<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import TableSort from "@/components/table-sort.vue";
import ModalsTemplate from "@/components/modals-template.vue";
import ConfirmDialog from "../confirm-dialog.vue";

export default {
  components: {
    TableSort,
    ModalsTemplate,
    ConfirmDialog,
  },

  props: {
    basket: {
      type: Object,
      required: true,
    },
  },

  data: (vm) => ({
    dialog: true,

    options: {},
    page: 1,
    itemsPerPage: 50,

    isDelete: false,
    isLoading: false,
    selected: {},

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    ln: "basket.line_item",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },

    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  computed: {
    ...mapGetters("basket-line-item", ["GET", "GET_COUNT"]),
    size: (vm) => vm.$store.getters.GET_SIZE,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    headers: (vm) => [
      {
        text: "#",
        value: "number",
        width: 40,
        align: "center",
      },

      {
        text: vm.tn("table.productName"),
        value: "productName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.manufacturerName"),
        value: "manufacturerName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.shelfLife"),
        value: "shelfLife",
        _filter: (v) => vm.$date(v),
      },
      {
        text: vm.tn("table.load"),
        value: "load",
      },
      {
        text: vm.tn("table.quantity"),
        value: "quantity",
        _filter: (v) => vm.$sum(v),
      },
      {
        text: vm.tn("table.price"),
        value: "price",
        _filter: (v) => vm.$sum(v),
      },
      {
        text: vm.tn("table.note"),
        value: "note",
      },

      ...vm.$baseTableColumns(vm),
      { text: "", value: "actions", width: 10, align: "center" },
    ],

    sortItems: (vm) =>
      vm.headers
        .filter(
          (x) => x.value != "actions" && x.value != "number" && x._sort != false
        )
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),
  },

  methods: {
    ...mapActions("basket-line-item", ["GET_API", "REMOVE_API"]),

    async getEntries() {
      this.isLoading = true;
      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;

      const filterParams = { basketGuid: this.basket.guid };
      const options = { options: { ...this.options }, filterParams };

      await this.GET_API(options);
      this.isLoading = false;
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onDeleteShow(item) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isDelete = true));
    },

    async onDelete({ setLoading, close }) {
      setLoading(true);
      await this.REMOVE_API(this.selected.guid);
      setLoading(false);
      close();
      await this.getEntries();
      if (this.entriesCount == 0) {
        this.dialog = false;
      }
    },
  },
};
</script>

<template>
  <modals-template hideToolbar :width="1500" v-model="dialog">
    <confirm-dialog
      :text="tn('delete_alert')"
      @close="isDelete = false"
      v-if="isDelete"
      @accept="onDelete"
    />

    <div class="d-flex align-items-center pb-4">
      <h3>{{ basket.priceListProvider }}</h3>

      <v-spacer />

      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          append-icon="mdi-magnify"
          @click:clear="onClear"
          clearable
        />
      </div>

      <div class="px-2" />

      <v-btn
        @click="dialog = false"
        outlined
        color="error"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-data-table
      :height="size.height - 270"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
      class="fixed-right"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td v-else-if="head._filter" :key="head.value">
                <div
                  v-text="head._filter(item[head.value])"
                  class="text-nowrap"
                />
              </td>

              <td
                v-else-if="head.value == 'actions'"
                :key="head.value"
                class="px-0"
              >
                <div class="d-flex tb-action-wrap">
                  <v-btn
                    @click="onDeleteShow(item)"
                    :title="tn('table.actions.delete')"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>

              <td v-else :key="head.value">
                <div
                  :style="{ minWidth: head.minWidth + 'px' }"
                  v-text="item[head.value]"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </modals-template>
</template>
