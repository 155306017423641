<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import TableSort from "@/components/table-sort.vue";
import CrudAccessVue from "@/mixins/crud-access.vue";
import AddPriceList from "@/components/modals/document/add-price-list.vue";
import PriceListFilter from "@/components/modals/document/price-list-filter.vue";
import PriceListShow from "@/components/modals/document/price-list-show.vue";

export default {
  components: {
    ConfirmDialog,
    AddPriceList,
    TableSort,
    PriceListFilter,
    PriceListShow,
  },

  data: (vm) => ({
    options: {},
    page: 1,
    itemsPerPage: 50,

    isFilter: false,

    isAdd: false,
    isDelete: false,

    isLoading: false,
    selected: {},

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    filterParams: {},

    isInited: false,
    isPriceListShow: false,

    ln: "price_list",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  mixins: [CrudAccessVue],

  computed: {
    ...mapGetters("price-list", ["GET", "GET_COUNT"]),
    size: (vm) => vm.$store.getters.GET_SIZE,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    headers: (vm) => [
      {
        text: "#",
        value: "number",
        width: 40,
        align: "center",
      },
      {
        text: vm.tn("table.provider"),
        value: "provider",
        minWidth: 200,
      },
      {
        text: vm.tn("table.documentDate"),
        value: "documentDate",
        _filter: (v) => vm.$date(v),
      },
      {
        text: vm.tn("table.itemsCount"),
        value: "itemsCount",
        _filter: (v) => vm.$sum(v),
      },
      {
        text: vm.tn("table.timePublished"),
        value: "timePublished",
        _filter: (v) => vm.$dateTime(v),
      },
      {
        text: vm.tn("table.isCurrent"),
        value: "isCurrent",
        _filter: (v) => vm.tn(v ? "current.active" : "current.not_active"),
      },

      ...vm.$baseTableColumns(vm),

      { text: "", value: "actions", width: 10, align: "center" },
    ],

    sortItems: (vm) =>
      vm.headers
        .filter(
          (x) => x.value != "actions" && x.value != "number" && x._sort != false
        )
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),
  },

  created() {
    const q = this.$route.query;
    const sq = this.$browserStore.getSession("tb" + this.$route.path) || {};
    const page = q.page || sq.page;
    const per_page = q.per_page || sq.per_page;
    this.page = this.$number(page) || 1;
    this.itemsPerPage = this.$number(per_page) || 50;

    this.init();
  },

  methods: {
    ...mapActions("price-list", ["GET_API", "REMOVE_API"]),

    async init() {
      this.isInited = true;
    },

    async getEntries() {
      this.isLoading = true;
      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;

      const filterParams = this.$filterParamsMap(this.filterParams);
      const options = { options: { ...this.options }, filterParams };

      await this.GET_API(options);
      this.isLoading = false;
      this.setRoute();
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    setRoute() {
      const query = { page: this.options.page, per_page: this.itemsPerPage };
      const page = this.$number(this.$route.query.page);
      const per_page = this.$number(this.$route.query.per_page);
      if (query.page != page || query.per_page != per_page) {
        this.$router.replace({ query });
        this.$browserStore.setSession("tb" + this.$route.path, query);
      }
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);
      await this.REMOVE_API(this.selected.guid);
      this.getEntries();
      setLoading(false);
      close();
    },

    async onExportUnlinkedExcel({ item }) {
      this.$store.dispatch("SET_LOADING", true);
      const url = 'PriceList/Export/Unlinked';
      await this.$axios.$postResFile(url, { guid: item.guid });
      this.$store.dispatch("SET_LOADING", false);
    },

    onFilter(v) {
      this.filterParams = v;
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onShowData({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isPriceListShow = true));
    },

    onDeleteAlert({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isDelete = true));
    },
  },
};
</script>

<template>
  <div>
    <AddPriceList
      @saved="$router.replace('/price-list-items')"
      v-if="isAdd"
      @close="isAdd = false"
    />

    <PriceListFilter
      :value="filterParams"
      @input="onFilter"
      v-if="isFilter"
      @close="isFilter = false"
    />

    <PriceListShow
      :value="selected"
      v-if="isPriceListShow"
      @close="() => {
          getEntries();
          isPriceListShow = false;
        }
        "
    />

    <confirm-dialog
      :text="tn('delete_alert')"
      @close="isDelete = false"
      v-if="isDelete"
      @accept="onDelete"
    />

    <div class="d-flex align-items-center pb-4">
      <h3>{{ tn("title") }}</h3>

      <v-spacer />

      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <v-btn
        @click="isAdd = true"
        outlined
        color="primary"
        v-if="isAccessAdd"
      >
        {{ tn("import_excel") }}
      </v-btn>

      <div class="px-2" />

      <v-badge
        dot
        overlap
        color="error"
        :value="$filterItemsCount(filterParams)"
      >
        <v-btn
          :title="tn('filter_btn')"
          @click="isFilter = true"
          outlined
          color="primary"
          min-width="38"
          width="38"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-badge>

      <div class="px-2" />

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          append-icon="mdi-magnify"
          @click:clear="onClear"
          clearable
        />
      </div>
    </div>

    <v-data-table
      v-if="isInited"
      :height="size.height - 220"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
      class="fixed-right"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td
                v-else-if="head.value == 'actions'"
                :key="head.value"
                class="px-0"
              >
                <div class="d-flex tb-action-wrap">
                  <v-btn
                    :title="tn('table.actions.export_unlinked')"
                    @click="onExportUnlinkedExcel({ item })"
                    icon
                  >
                    <v-icon> mdi-file-excel-outline </v-icon>
                  </v-btn>

                  <v-btn
                    :title="tn('table.actions.show_data')"
                    @click="onShowData({ item })"
                    icon
                  >
                    <v-icon> mdi-open-in-new </v-icon>
                  </v-btn>

                  <v-btn
                    :title="tn('table.actions.delete')"
                    v-if="isAccessDelete"
                    @click="onDeleteAlert({ item })"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>

              <td
                v-else-if="head._filter"
                :key="head.value"
              >
                <div
                  v-text="head._filter(item[head.value])"
                  class="text-nowrap"
                />
              </td>

              <td
                v-else
                :key="head.value"
              >
                <div
                  :style="{ minWidth: head.minWidth + 'px' }"
                  v-text="item[head.value]"
              />
            </td>
          </template>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</div></template>
