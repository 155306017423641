import router from "@/router";

import store from "../store";

export default (prefix, url) => {
  const key = (url || router.currentRoute.path || "").replace(/\//, "");
  const action = `${prefix}_${key}`;
  const user = store.getters["auth/GET_USER"];
  const claims = store.getters["auth/GET_CLAIMS"];
  if (user.isSuperUser || (action && claims.includes(action))) return true;
  else return false;
};
