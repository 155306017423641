import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: getModules(),

  state: {
    snackbar: {
      text: false,
      color: "success",
      isShow: false,
    },
    size: {
      height: 0,
      width: 0,
    },

    loading: false,
    initing: false,
  },

  mutations: {
    SET_SNACKBAR(state, snackbar) {
      snackbar.isShow = true;
      state.snackbar = snackbar;
    },

    SET_SIZE(state, payload) {
      state.size = payload;
    },

    SET_LOADING(state, payload) {
      state.loading = payload;
    },

    SET_INITING(state, payload) {
      state.initing = payload;
    },
  },

  actions: {
    async INIT_STATES({ commit, rootState, }) {
      commit("SET_INITING", true);
      if (rootState.auth.user.exist) {
        // await dispatch("reference/INIT_APP_TYPES");
      }
      commit("SET_INITING", false);
    },

    SET_SNACKBAR({ commit, }, payload) {
      commit("SET_SNACKBAR", payload);
    },

    SET_SIZE({ commit, }, payload) {
      commit("SET_SIZE", payload);
    },

    SET_LOADING({ commit, }, payload) {
      commit("SET_LOADING", payload);
    },

    SET_INITING({ commit, }, payload) {
      commit("SET_INITING", payload);
    },
  },

  getters: {
    GET_SNACKBAR: (state) => state.snackbar,
    GET_SIZE: (state) => state.size,
    GET_LOADING: (state) => state.loading,
    GET_INITING: (state) => state.initing,
  },
});

function getModules() {
  const modulesCtx = require.context("./", true, /[A-Za-z0-9-_,\s]+\.js$/i);
  const modules = {};
  // const ignores = [];
  // if (ignores.indexOf(key) < 0 && key != "./index.js") {

  modulesCtx.keys().forEach((key) => {
    if (key == "./index.js") return;
    let module = modulesCtx(key);
    module.namespaced = true;
    const name = key.slice(key.lastIndexOf('/') + 1, key.length - 3)
    modules[name] = module;
  });
  return modules;
}
