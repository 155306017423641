import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

const url = "HomePageTile"

export const state = () => ({
  itemsData: {
    items: [],
    total: 0,
  },
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    };
  },
};

export const actions = {
  async GET_API({ commit, }, { options }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    commit("SET_ITEMS");
    const params = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      search: options.search,
    };
    const request = { progress: false, cancelToken: cancelSource.token, };
    const res = await vm.$axios.$get(url, params, request);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async ADD_API(ctx, payload) {
    if (payload.guid) {
      const res = await vm.$axios.$put(`${url}/${payload.guid}`, payload);
      return res?.succeeded ? res.data : null;
    } else {
      const res = await vm.$axios.$post(url, payload);
      return res?.succeeded ? res.data : null;
    }
  },

  async REMOVE_API(ctx, guid) {
    const res = await vm.$axios.$delete(`${url}/${guid}`);
    return res?.succeeded;
  },

  async GET_BY_GUID_API(ctx, { guid }) {
    if (!guid) return false;
    const res = await vm.$axios.$get(`${url}/${guid}`);
    return res?.succeeded ? res.data : null;
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
};
