<script>
import ModalsTemplate from "../modals-template.vue";

import getProductTypes from "../../utils/product-types";

export default {
  components: { ModalsTemplate },

  props: {
    value: {
      type: Object,
    },
  },

  data: (vm) => ({
    dialog: true,

    formData: {},

    ln: "product.filter",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  computed: {
    productTypes: (vm) => getProductTypes(vm),
  },

  created() {
    this.formData = { ...this.value };
  },

  methods: {
    async onApply() {
      this.$emit("input", this.formData);
      this.dialog = false;
    },

    async onReset() {
      this.formData = {};
      this.$nextTick(() => this.onApply());
    },
  },
};
</script>

<template>
  <modals-template :width="500" :title="tn('title')" v-model="dialog">
    <v-select
      :label="tn('form.type')"
      outlined
      :items="productTypes"
      item-text="name"
      item-value="id"
      dense
      autofocus
      v-model="formData.type"
      hide-details
      class="mb-4"
      clearable
    />

    <AutocompleteBox
      :label="tn('form.brand')"
      class="mb-4"
      disableAdd
      v-model="formData.brand"
      :searchStore="'brand'"
      clearable
    />
    <AutocompleteBox
      :label="tn('form.manufacturer')"
      class="mb-4"
      disableAdd
      v-model="formData.manufacturer"
      :searchStore="'manufacturer'"
      clearable
    />
    <AutocompleteBox
      :label="tn('form.internationalNonproprietaryName')"
      class="mb-4"
      disableAdd
      v-model="formData.internationalNonproprietaryName"
      :searchStore="'international-name'"
      clearable
    />

    <div class="d-flex justify-space-between pt-1">
      <v-btn width="48%" color="error" @click="onReset" elevation="0">
        {{ tn("reset") }}
      </v-btn>
      <v-btn width="48%" color="primary" @click="onApply" elevation="0">
        {{ tn("apply") }}
      </v-btn>
    </div>
  </modals-template>
</template>
