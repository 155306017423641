import Vue from "vue";
const vm = Vue.prototype
const rules = {
  required: (v) => !!v || v == 0 || "",
  array: (v) => !!vm.$array(v).length,
  non_zero: (v) => v > 0 || "",
  phone: (v) => `${v || ""}`.length == 9 || "",
};

Vue.prototype.$rules = rules;
