<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import AddPriceListProvider from "@/components/modals/crm/add-price-list-provider.vue";
import TableSort from "@/components/table-sort.vue";
import CrudAccessVue from "@/mixins/crud-access.vue";
import PriceListProviderFilter from "@/components/modals/crm/price-list-provider-filter.vue";
import BindProviderUserToProviderTable from "@/components/modals/crm/bind-provider-user-to-provider-table.vue";

export default {
  components: {
    ConfirmDialog,
    AddPriceListProvider,
    TableSort,
    PriceListProviderFilter,
    BindProviderUserToProviderTable,
  },

  data: (vm) => ({
    options: {},
    page: 1,
    itemsPerPage: 50,

    isFilter: false,

    isAdd: false,
    isDelete: false,
    isBindProviderUserToProvider: false,

    isLoading: false,
    selected: {},

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    filterParams: {},

    isInited: false,

    ln: "price_list_provider",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  mixins: [CrudAccessVue],

  computed: {
    ...mapGetters("price-list-provider", ["GET", "GET_COUNT", "GET_STATUSES"]),
    size: (vm) => vm.$store.getters.GET_SIZE,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    headers: (vm) => [
      {
        text: "#",
        value: "number",
        width: 40,
        align: "center",
      },
      {
        text: vm.tn("table.name"),
        value: "name",
        minWidth: 200,
      },
      {
        text: vm.tn("table.description"),
        value: "description",
        minWidth: 200,
      },
      {
        text: vm.tn("table.address"),
        value: "address",
        minWidth: 200,
      },
      {
        text: vm.tn("table.phone"),
        value: "phone",
        _html: true,
        width: 10,
        _filter: (v) => {
          const x = `${v || ""}`
            .split(", ")
            .map((x) => `<span class="text-nowrap">${x}<br/></span>`)
            .join("");
          return x;
        },
      },
      {
        text: vm.tn("table.bankInfo"),
        value: "bankInfo",
        minWidth: 250,
      },
      {
        text: vm.tn("table.email"),
        value: "email",
      },
      {
        text: vm.tn("table.inn"),
        value: "inn",
      },
      {
        text: vm.tn("table.telegramPhoneNumber"),
        value: "telegramPhoneNumber",
      },
      {
        text: vm.tn("table.priceListValidDaysCount"),
        value: "priceListValidDaysCount",
        _filter: (v) => vm.$sum(v),
      },
      {
        text: vm.tn("table.minAmountToPurchase"),
        value: "minAmountToPurchase",
        _filter: (v) => vm.$sum(v),
      },
      {
        text: vm.tn("table.minAmountToPurchaseWithDelivery"),
        value: "minAmountToPurchaseWithDelivery",
        _filter: (v) => vm.$sum(v),
      },
      {
        text: vm.tn("table.regions"),
        value: "regions",
      },
      {
        text: vm.tn("table.status"),
        value: "status",
        _filter: (id) => vm.GET_STATUSES.find((x) => x.id == id)?.name,
      },
      {
        text: vm.tn("table.lastPriceUploadTime"),
        value: "lastPriceUploadTime",
        _filter: (id) => vm.$dateTime(id),
        width: 130,
      },
      {
        text: vm.tn("table.is_public"),
        value: "isPublic",
        _filter: (v) => vm.tn(v ? "table.yes" : "table.no"),
      },
      {
        text: vm.tn("table.is_available_basket_to_order"),
        value: "isAvailableBasketToOrder",
        _filter: (v) => vm.tn(v ? "table.yes" : "table.no"),
      },
      {
        text: vm.tn("table.price_list_viewer_phone"),
        value: "priceListViewerPhone",
      },

      ...vm.$baseTableColumns(vm),

      { text: "", value: "actions", width: 10, align: "center" },
    ],

    sortItems: (vm) =>
      vm.headers
        .filter(
          (x) => x.value != "actions" && x.value != "number" && x._sort != false
        )
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),
  },

  created() {
    const q = this.$route.query;
    const sq = this.$browserStore.getSession("tb" + this.$route.path) || {};
    const page = q.page || sq.page;
    const per_page = q.per_page || sq.per_page;
    this.page = this.$number(page) || 1;
    this.itemsPerPage = this.$number(per_page) || 50;

    this.init();
  },

  methods: {
    ...mapActions("price-list-provider", [
      "GET_API",
      "INIT_STATUSES",
      "REMOVE_API",
    ]),
    ...mapActions("region", ["GET_REGIONS_API"]),

    async init() {
      this.$store.dispatch("SET_LOADING", true);
      await this.INIT_STATUSES();
      await this.GET_REGIONS_API();
      this.$store.dispatch("SET_LOADING", false);
      this.isInited = true;
    },

    async getEntries() {
      this.isLoading = true;
      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;

      const filterParams = this.$filterParamsMap(this.filterParams);
      const options = { options: { ...this.options }, filterParams };

      await this.GET_API(options);
      this.isLoading = false;
      this.setRoute();
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    setRoute() {
      const query = { page: this.options.page, per_page: this.itemsPerPage };
      const page = this.$number(this.$route.query.page);
      const per_page = this.$number(this.$route.query.per_page);
      if (query.page != page || query.per_page != per_page) {
        this.$router.replace({ query });
        this.$browserStore.setSession("tb" + this.$route.path, query);
      }
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);
      await this.REMOVE_API(this.selected.guid);
      this.getEntries();
      setLoading(false);
      close();
    },

    onEdit({ item }) {
      this.selected = item ? { ...item } : {};
      this.$nextTick(() => (this.isAdd = true));
    },

    onFilter(v) {
      this.filterParams = v;
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onBindProviderUserToProvider({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isBindProviderUserToProvider = true));
    },
  },
};
</script>

<template>
  <div>
    <price-list-provider-filter
      :value="filterParams"
      @input="onFilter"
      v-if="isFilter"
      :statuses="GET_STATUSES"
      @close="isFilter = false"
    />

    <AddPriceListProvider
      :editData="selected"
      @saved="getEntries"
      v-if="isAdd"
      @close="isAdd = false"
    />

    <BindProviderUserToProviderTable
      :value="selected"
      @saved="getEntries"
      v-if="isBindProviderUserToProvider"
      @close="isBindProviderUserToProvider = false"
    />

    <confirm-dialog
      :text="tn('delete_alert')"
      @close="isDelete = false"
      v-if="isDelete"
      @accept="onDelete"
    />

    <div class="d-flex align-items-center pb-4">
      <h3>{{ tn("title") }}</h3>

      <v-spacer />

      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <v-btn
        min-width="38"
        width="38"
        :title="tn('add_btn')"
        @click="onEdit"
        color="primary"
        outlined
        v-if="isAccessAdd"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <div class="px-2" />

      <v-badge
        dot
        overlap
        color="error"
        :value="$filterItemsCount(filterParams)"
      >
        <v-btn
          :title="tn('filter_btn')"
          @click="isFilter = true"
          outlined
          color="primary"
          min-width="38"
          width="38"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-badge>

      <div class="px-2" />

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          append-icon="mdi-magnify"
          @click:clear="onClear"
          clearable
        />
      </div>
    </div>

    <v-data-table
      v-if="isInited"
      :height="size.height - 220"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
      class="fixed-right"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td
                v-else-if="head.value == 'actions'"
                :key="head.value + 1"
                class="px-0"
              >
                <div class="d-flex tb-action-wrap">
                  <v-btn
                    :title="tn('table.actions.edit')"
                    v-if="isAccessEdit"
                    @click="onEdit({ item })"
                    icon
                  >
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                  <div v-else style="width: 36px" />
                  <v-btn
                    :title="tn('table.actions.delete')"
                    v-if="isAccessDelete"
                    @click="isDelete = true"
                    icon
                  >
                    <v-icon> mdi-delete </v-icon>
                  </v-btn>
                  <v-btn
                    :title="tn('table.actions.plus')"
                    @click="onBindProviderUserToProvider({ item })"
                    icon
                  >
                    <v-icon> mdi-plus </v-icon>
                  </v-btn>
                </div>
              </td>

              <td v-else-if="head._filter" :key="head.value + 2">
                <div
                  v-if="head._html"
                  v-html="head._filter(item[head.value])"
                />
                <div
                  v-else
                  v-text="head._filter(item[head.value])"
                  class="text-nowrap"
                />
              </td>

              <td v-else :key="head.value + 3">
                <div
                  :style="{ minWidth: head.minWidth + 'px' }"
                  v-text="item[head.value]"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
