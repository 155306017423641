<script>
import LoadingDialog from "./components/loading-dialog.vue";
import * as debounce from "debounce";

export default {
  components: { LoadingDialog },

  data: () => ({
    //
  }),

  watch: {
    "$vuetify.theme.dark"(v) {
      localStorage.setItem("theme--dark", v);
    },
  },

  computed: {
    snackbar: (vm) => vm.$store.getters.GET_SNACKBAR,

    isIniting: (vm) => vm.$store.getters.GET_INITING,
    isLoading: (vm) => vm.$store.getters.GET_LOADING,
  },

  created() {
    this.onResize();
  },

  methods: {
    onResize: debounce(function () {
      const size = {
        width: window.innerWidth,
        height: window.innerHeight,
      };

      this.$store.dispatch("SET_SIZE", size);
    }, 500),
  },
};
</script>

<template>
  <v-app v-resize="onResize">
    <v-snackbar
      top
      v-model="snackbar.isShow"
      :color="snackbar.color"
      :timeout="8500"
    >
      <div v-html="snackbar.text" />
      <template slot="action">
        <v-btn
          icon
          :title="'Закрыть'"
          dark
          text
          @click="snackbar.isShow = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <router-view v-if="!isIniting" />
    <LoadingDialog :overlay="isIniting || isLoading" />
  </v-app>
</template>
