<script>
import DateBox from "@/components/fields/date-box.vue";
import ModalsTemplate from "../../modals-template.vue";

export default {
  components: { ModalsTemplate, DateBox },

  props: {
    value: {
      type: Object,
    },
  },

  data: (vm) => ({
    dialog: true,

    formData: {},

    ln: "price_list.filter",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  created() {
    this.formData = { ...this.value };
  },

  methods: {
    async onApply() {
      if (!this.formData.fromDocumentDate)
        delete this.formData.fromDocumentDate;
      if (!this.formData.tillDocumentDate)
        delete this.formData.tillDocumentDate;

      this.$emit("input", this.formData);
      this.dialog = false;
    },

    async onReset() {
      this.formData = {};
      this.$nextTick(() => this.onApply());
    },
  },
};
</script>

<template>
  <modals-template :width="450" :title="tn('title')" v-model="dialog">
    <div class="pb-3" />
    <AutocompleteBox
      :label="tn('form.provider')"
      disableAdd
      v-model="formData.provider"
      :searchStore="'price-list-provider'"
      clearable
    />

    <div class="pb-3" />
    <date-box
      :label="tn('form.fromDocumentDate')"
      v-model="formData.fromDocumentDate"
      clearable
      required
      hide-details
      dense
    />

    <div class="pb-3" />
    <date-box
      :label="tn('form.tillDocumentDate')"
      v-model="formData.tillDocumentDate"
      clearable
      required
      hide-details
      dense
    />

    <div class="d-flex justify-space-between pt-5">
      <v-btn width="48%" color="error" @click="onReset" elevation="0">
        {{ tn("reset") }}
      </v-btn>
      <v-btn width="48%" color="primary" @click="onApply" elevation="0">
        {{ tn("apply") }}
      </v-btn>
    </div>
  </modals-template>
</template>
