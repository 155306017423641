<script>
import { mapActions } from "vuex";

import ModalsTemplate from "../../modals-template.vue";
import FieldTextVariants from "../../field-text-variants.vue";

export default {
  components: { ModalsTemplate, FieldTextVariants },

  props: {
    editData: {
      type: Object,
    },
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,

    formData: { name: null },

    ln: "price_type.add",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions("price-type", ["SEARCH", "ADD_API", "GET_BY_GUID_API"]),

    async init() {
      if (this.editData?.guid) {
        this.$store.dispatch("SET_LOADING", true);
        const data = await this.GET_BY_GUID_API(this.editData);
        if (data) this.formData = data;
        this.$store.dispatch("SET_LOADING", false);
      }
    },

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const data = await this.ADD_API(this.formData);
        this.isLoading = false;
        if (data) {
          this.$emit("saved", data);
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template :width="600" :title="tn('title')" v-model="dialog">
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-text-field
        :label="tn('form.name')"
        autofocus
        outlined
        v-model="formData.name"
        required
        :rules="[$rules.required]"
        dense
        hide-details
      />

      <div class="pb-2" />

      <field-text-variants
        :height="75"
        :object="formData"
        :searchProp="SEARCH"
      />

      <div class="pb-3" />

      <v-text-field
        dense
        :label="tn('form.order_number')"
        outlined
        v-model="formData.orderNumber"
        required
        :rules="[$rules.required]"
        @keyup.enter="onSave"
        type="number"
        hide-details
      />

      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
