"use strict";

import Vue from "vue";
const vm = Vue.prototype;
import axios from "axios";

import router from "@/router.js";
import store from "@/store/index.js";

axios.defaults.baseURL = process.env.VUE_APP_AXIOS_BASE_URL;

const _axios = axios.create();
_axios.interceptors.request.use(
  (req) => {
    const token = vm.$cookies.get("token");
    if (token) req.headers.Authorization = `bearer ${token}`;
    return req;
  },

  (config) => {
    const token = vm.$cookies.get("token");
    if (token) config.headers.Authorization = `bearer ${token}`;
    return config;
  },

  (error) => {
    if (!axios.isCancel(error)) onError(error?.response?.status);
    return Promise.reject(error);
  },
);

_axios.interceptors.response.use(
  (res) => {
    if (
      res?.status == 200 &&
      res.headers["content-type"] == "application/octet-stream"
    ) {
      return res;
    }

    const data = res?.data;
    if (data) {
      if (data.succeeded) {
        return data;
      } else {
        showError({ response: res, });
        return 0;
      }
    }
  },

  (error) => {
    if (!axios.isCancel(error)) {
      onError(error?.response?.status);
      showError(error);
    }
    return Promise.reject(error);
  },
);

const $get = async (url, param, cf) => {
  try {
    cf = cf || {};
    cf.params = param || {};
    return await _axios.get(url, cf);
  } catch {
    return null;
  }
};

const $postResFile = async (url, params = {}, cf) => {
  try {
    const config = { ...(cf || {}), responseType: "blob", };
    const name = params.name || new Date().toISOString();
    delete params.name;
    const res = await _axios.post(url, params, config);
    const link = document.createElement("a");
    const objurl = URL.createObjectURL(res.data);
    link.href = objurl;
    const nm = `${name} - ${getExcelName(res)}`;
    link.setAttribute("download", nm);
    document.body.appendChild(link);
    link.click();
    link.remove();
    URL.revokeObjectURL(objurl);
    return true;
  } catch (error) {
    return null;
  }
};

const $post = async (url, param, cf) => {
  try {
    return await _axios.post(url, param || {}, cf);
  } catch {
    return null;
  }
};

const $put = async (url, param) => {
  try {
    return await _axios.put(url, param || {});
  } catch {
    return null;
  }
};

const $patch = async (url, param) => {
  try {
    return await _axios.patch(url, param || {});
  } catch {
    return null;
  }
};

const $delete = async (url, param) => {
  try {
    return await _axios.delete(url, param);
  } catch {
    return null;
  }
};

const $axios = { $get, $postResFile, $post, $put, $patch, $delete, ..._axios, };

const plugin = {
  install(Vue) {
    Vue.axios = _axios;
    window.axios = _axios;
    Object.defineProperties(Vue.prototype, {
      $axios: {
        get() {
          return $axios;
        },
      },
    });
  },
};

Vue.use(plugin);

function onError(status) {
  if ((status == 401 || status == 403) && router.currentRoute.path != "/") {
    store.dispatch("auth/LOGOUT");
  }
}

function showError(error) {
  let msg = null;

  if (error?.code != "ERR_NETWORK" && error?.response) {
    const res = error.response || {};
    if (res.status == 401 || res.status == 403) {
      msg = "Пожалуйста, войдите снова, срок действия вашего ключа истек";
    } else {
      const data = res.data || {};
      const msgs = vm.$array(data.messages);
      if (msgs.length) msg = msgs.join("<br/>");
      else msg = `Внутренняя ошибка. (код-${res.status})`;
    }
  } else msg = "Подключение к Интернету отсутствует";
  store.dispatch("SET_SNACKBAR", { text: msg, color: "error", });
}

function getExcelName(res) {
  let name = "";
  let disposition = res.headers["content-disposition"];
  if (disposition && disposition.indexOf("attachment") !== -1) {
    const nameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = nameRegex.exec(disposition);
    if (matches != null && matches[1]) name = matches[1].replace(/['"]/g, "");
  }
  return name;
}
