<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import TableSort from "@/components/table-sort.vue";
import BasketFilter from "@/components/modals/document/basket-filter.vue";
import BasketLineItem from "@/components/modals/document/basket-line-item.vue";

export default {
  components: {
    TableSort,
    BasketFilter,
    BasketLineItem,
  },

  data: (vm) => ({
    options: {},
    page: 1,
    itemsPerPage: 50,

    isFilter: false,

    isLoading: false,
    selected: {},

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    filterParams: {},
    priceListViewerGuid: vm.$route.params.viewer,

    isInited: false,

    ln: "basket",
    tn: (n) => vm.$t(vm.ln + "." + n),

    isShowItems: false,
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("basket", ["GET", "GET_COUNT", "GET_STATUSES"]),
    size: (vm) => vm.$store.getters.GET_SIZE,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    headers: (vm) =>
      [
        {
          text: "#",
          value: "number",
          width: 40,
          align: "center",
        },

        vm.$route.params.viewer
          ? null
          : {
              text: vm.tn("table.priceListViewer"),
              value: "priceListViewer",
              minWidth: 200,
            },

        {
          text: vm.tn("table.priceListProvider"),
          value: "priceListProvider",
          minWidth: 200,
        },
        {
          text: vm.tn("table.priceListDocumentDate"),
          value: "priceListDocumentDate",
          _filter: (v) => vm.$dateTime(v),
        },
        {
          text: vm.tn("table.itemsCount"),
          value: "itemsCount",
          _filter: (v) => vm.$sum(v),
        },
        {
          text: vm.tn("table.totalAmount"),
          value: "totalAmount",
          _filter: (v) => vm.$sum(v),
        },
        {
          text: vm.tn("table.priceType"),
          value: "priceType",
          _filter: (v) => vm.$sum(v),
        },
        {
          text: vm.tn("table.status"),
          value: "status",
          _filter: (v) => vm.GET_STATUSES.find((x) => x.id == v)?.name,
        },
        {
          text: vm.tn("table.timeStarted"),
          value: "timeStarted",
          _filter: (v) => vm.$dateTime(v),
        },
        {
          text: vm.tn("table.timeEnded"),
          value: "timeEnded",
          _filter: (v) => vm.$dateTime(v),
        },

        ...vm.$baseTableColumns(vm),
        { text: "", value: "actions", width: 10, align: "center" },
      ].filter((x) => x),

    sortItems: (vm) =>
      vm.headers
        .filter(
          (x) => x.value != "actions" && x.value != "number" && x._sort != false
        )
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),
  },

  created() {
    this.init();
  },

  beforeDestroy() {
    this.GET_API({ stop: true });
  },

  methods: {
    ...mapActions("basket", ["GET_API", "INIT_STATUSES", "REMOVE_API"]),

    async init() {
      this.$store.dispatch("SET_LOADING", true);
      await this.INIT_STATUSES();
      this.$store.dispatch("SET_LOADING", false);
      this.isInited = true;
    },

    async getEntries() {
      this.isLoading = true;
      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;

      const filterParams = this.$filterParamsMap(this.filterParams);
      if (this.priceListViewerGuid) {
        filterParams.priceListViewerGuid = this.priceListViewerGuid;
      }
      const options = { options: { ...this.options }, filterParams };

      await this.GET_API(options);
      this.isLoading = false;
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    onFilter(v) {
      this.filterParams = v;
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onShowItems(item) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowItems = true));
    },
  },
};
</script>

<template>
  <div :class="priceListViewerGuid && 'pa-5'">
    <BasketFilter
      :value="filterParams"
      @input="onFilter"
      v-if="isFilter"
      :statuses="GET_STATUSES"
      @close="isFilter = false"
      :hideViewer="!!$route.params.viewer"
    />

    <BasketLineItem
      :basket="selected"
      v-if="isShowItems"
      @close="
        () => {
          isShowItems = false;
          getEntries();
        }
      "
    />

    <div class="d-flex align-items-center pb-4">
      <h3>{{ $route.params.name || tn("title") }}</h3>

      <v-spacer />

      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <v-badge
        dot
        overlap
        color="error"
        :value="$filterItemsCount(filterParams)"
      >
        <v-btn
          :title="tn('filter_btn')"
          @click="isFilter = true"
          outlined
          color="primary"
          min-width="38"
          width="38"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-badge>

      <div class="px-2" />

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          append-icon="mdi-magnify"
          @click:clear="onClear"
          clearable
        />
      </div>
    </div>

    <v-data-table
      v-if="isInited"
      :height="size.height - (priceListViewerGuid ? 160 : 220)"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
      class="fixed-right"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td v-else-if="head._filter" :key="head.value">
                <div
                  v-text="head._filter(item[head.value])"
                  class="text-nowrap"
                />
              </td>

              <td
                v-else-if="head.value == 'actions'"
                :key="head.value"
                class="px-0"
              >
                <div class="d-flex tb-action-wrap">
                  <v-btn
                    @click="onShowItems(item)"
                    :title="tn('table.actions.show_items')"
                    icon
                  >
                    <v-icon>mdi-cart-check</v-icon>
                  </v-btn>
                </div>
              </td>

              <td v-else :key="head.value">
                <div
                  :style="{ minWidth: head.minWidth + 'px' }"
                  v-text="item[head.value]"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
