<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import TableSort from "@/components/table-sort.vue";

export default {
  components: {
    TableSort,
  },

  data: (vm) => ({
    options: {},
    page: 1,
    itemsPerPage: 50,

    loaders: {},
    selected: {},

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    ln: "country",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("country", ["GET", "GET_COUNT", "GET_TYPES"]),

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    headers: (vm) => [
      { text: "#", value: "number", width: 40, align: "center" },
      { text: vm.tn("table.name"), value: "name" },
      {
        text: vm.tn("table.type"),
        value: "type",
        _filter: (v) => vm.GET_TYPES.find((t) => t.id == v)?.name,
      },
      ...vm.$baseTableColumns(vm),
    ],

    sortItems: (vm) =>
      vm.headers
        .filter((x) => x.value != "number" && x._sort != false)
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),

    isLoading: (vm) => !!Object.keys(vm.loaders).length,
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions("country", ["GET_API", "INIT_TYPES"]),

    async init() {
      this.$store.dispatch("SET_LOADING", true);
      await this.INIT_TYPES();
      this.$store.dispatch("SET_LOADING", false);
    },

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;

      const options = { options: { ...this.options } };
      await this.GET_API(options);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-items-center pb-4">
      <h3>{{ tn("title") }}</h3>
      <v-spacer />
      <div>
        <v-select
          v-model="options.type"
          :items="GET_TYPES"
          item-value="id"
          item-text="name"
          :label="tn('type')"
          style="width: 200px"
          outlined
          dense
          hide-details
          @click:clear="onClear"
          clearable
        />
      </div>
      <div class="px-2" />
      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          @click:clear="onClear"
          clearable
          append-icon="mdi-magnify"
        />
      </div>
    </div>

    <v-data-table
      :height="size.height - 220"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td v-else-if="head._filter" :key="head.value">
                <div v-text="head._filter(item[head.value])" />
              </td>

              <td v-else :key="head.value">
                <div v-text="item[head.value]" />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
