import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
import { parseDateAsync } from "@/utils/parse-date-async";

const CancelToken = axios.CancelToken;
let cancelSource = null;
let cancelSource2 = null;

const url = "PriceList";

export const state = () => ({
  itemsData: {
    items: [],
    total: 0,
  },

  lineItems: [],
  priceList: {},
  priceTypes: [],
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    };
  },

  SET_LINE_ITEMS(state, payload) {
    const { lineItems, priceList, priceTypes } = payload || {};
    state.lineItems = vm.$array(lineItems);
    state.priceTypes = vm.$array(priceTypes);
    state.priceList = {
      ...(priceList || {}),
      itemsCount: state.lineItems.length,
    };
  },
};

export const actions = {
  async GET_API({ commit }, { options, filterParams = {} }) {
    cancelSource2?.cancel();
    cancelSource2 = CancelToken.source();

    commit("SET_ITEMS");
    const params = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      search: options.search,
      sortBy: options.sort_by,
      sortDirection: options.sort_direction,
      ...filterParams,
    };
    const request = { progress: false, cancelToken: cancelSource2.token };
    const res = await vm.$axios.$get(url, params, request);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async ADD_API({ state, commit, dispatch }, { fields, types }) {
    const lineItems = await correctItemsAsync(state.lineItems, fields);

    const params = {
      ...state.priceList,
      lineItems,
    };

    const data = await vm.$axios.$post(url, params);
    if (data?.succeeded) commit("SET_LINE_ITEMS");
    else {
      const typs = Object.values(fields)
        .filter((x) => `${x || ""}`.startsWith("price_"))
        .map((x) => x.split("_$#$")[1]);
      const prCols = types.filter((t) => typs.find((y) => t.guid == y));
      await dispatch(
        "price-list-items-delete/SET_LINE_ITEMS",
        {
          priceList: params,
          columns: prCols,
        },
        { root: true }
      );
      commit("SET_LINE_ITEMS");
    }

    return data?.succeeded;
  },

  async REMOVE_API(ctx, guid) {
    const res = await vm.$axios.$delete(`${url}/${guid}`);
    return res?.succeeded;
  },

  async ACTIVATE_API(ctx, payload) {
    const path = `${url}/Activate`;
    const res = await vm.$axios.$post(path, payload);
    return res?.succeeded;
  },

  async SEARCH(ctx, { value }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    const search = `${value || ""}`;
    if (search.length < 2) return [];

    const request = { progress: false, cancelToken: cancelSource.token };
    const res = await vm.$axios.$get(`${url}/lookUp`, { search }, request);
    return vm.$array(res?.data?.items);
  },

  async SET_LINE_ITEMS({ commit }, payload) {
    const providerGuid = payload?.priceList?.providerGuid;
    let status = false;
    if (providerGuid) {
      const res = await vm.$axios.$get(`priceType/name`);

      if (vm.$array(res?.data?.items).length) {
        payload.priceTypes = res.data.items;
        commit("SET_LINE_ITEMS", payload);
        status = true;
      }
    }

    return status;
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
  GET_LINE_ITEMS: (state) => state.lineItems,
  GET_PRICE_TYPES: (state) => state.priceTypes,
  GET_PRICE_LIST: (state) => state.priceList,
};

async function correctItemsAsync(lis, fields) {
  const fs = Object.fromEntries(
    // eslint-disable-next-line no-unused-vars
    Object.entries(fields || {}).filter(([_, v]) => v != null)
  );

  let lifs = []; // Line Item  Fields
  let lipfs = []; // Line Item Price Fields
  for (const key in fs) {
    const param = `${fs[key] || ""}`;
    if (param.startsWith("price_")) {
      lipfs.push({ key, param });
    } else {
      lifs.push({ key, param });
    }
  }

  const items = [...lis];

  let newItems = [];
  for (const lineItem of items) {
    let params = { _id: newItems.length + 1, prices: [] };
    lifs.forEach((li) => {
      params[li.param] = `${lineItem[li.key] || ""}`.trim();
    });

    params.prices = lipfs
      .map((lip) => {
        const pi = lip.param.split("_$#$");
        return {
          typeGuid: pi[1],
          amount: fixDecimal(lineItem[lip.key]),
        };
      })
      .filter((pi) => pi.amount != "");

    params.shelfLife = await parseDateAsync(params.shelfLife);
    if (!params.shelfLife) delete params.shelfLife;

    params.productName = `${params.productName || ""}`.trim();
    params.manufacturerName = `${params.manufacturerName || ""}`.trim();

    if (
      params.productName &&
      params.manufacturerName &&
      params.prices.length > 0
    ) {
      newItems.push(params);
    }
  }
  return newItems;
}

function fixDecimal(string) {
  let str = `${string || ""}`;

  if (str.includes(".")) {
    // Agar . bo'lsa u holda hamma , olib tashlash kerek
    str = str.replace(/,/g, "");
  } else {
    // Agar nuqta bo'lmasa va bir dona , bo'lsa u holda , -> .
    if ((str.match(/,/g) || []).length == 1) {
      str = str.replace(/,/g, ".");
    }
  }

  str = str.replace(/[^,.0-9]/g, "");

  if (str == "0") return "";
  return vm.$number(str) || str;
}
