<script>
import { mapActions } from "vuex";
import ModalsTemplate from "../../modals-template.vue";
import FieldTextVariants from "@/components/field-text-variants.vue";

export default {
  components: { ModalsTemplate, FieldTextVariants },

  props: {
    value: {
      type: Object,
    },

    statuses: {
      type: Array,
      default: () => [],
    },
  },

  data: (vm) => ({
    dialog: true,

    formData: {},

    ln: "price_list_provider_user.filter",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  created() {
    this.formData = { ...this.value };
  },

  methods: {
    ...mapActions("price-list-provider", ["SEARCH"]),

    async onApply() {
      if (!this.formData?.name) {
        delete this.formData.providerGuid;
        delete this.formData.guid;
        delete this.formData.name;
      }
      const params = { ...this.formData };
      params.providerGuid = this.formData.guid;

      this.$emit("input", params);
      this.dialog = false;
    },

    async onReset() {
      this.formData = {};
      this.$nextTick(() => this.onApply());
    },

    onSelect(item) {
      if (item) this.formData = { ...item };
    },
  },
};
</script>

<template>
  <modals-template :width="450" :title="tn('title')" v-model="dialog">
    <v-select
      :label="tn('form.status')"
      item-text="name"
      item-value="id"
      :items="statuses"
      outlined
      v-model="formData.status"
      hide-details
      dense
      clearable
    />

    <div class="pb-5" />

    <v-text-field
      :label="tn('form.providers')"
      item-text="name"
      item-value="guid"
      outlined
      v-model="formData.name"
      dense
      required
      clearable
      hide-details
    />

    <div class="pb-2" />

    <field-text-variants
      :height="100"
      :object="formData"
      :searchProp="SEARCH"
      isProduct
      @select="onSelect"
    />

    <div class="d-flex justify-space-between pt-5">
      <v-btn width="48%" color="error" @click="onReset" elevation="0">
        {{ tn("reset") }}
      </v-btn>
      <v-btn width="48%" color="primary" @click="onApply" elevation="0">
        {{ tn("apply") }}
      </v-btn>
    </div>
  </modals-template>
</template>
