<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: (vm) => ({
    isShow: false,

    auth: {
      oldPassword: null,
      newPassword: null,
    },
    confirmPassword: null,

    isLoading: false,
    isShowPass: false,

    passwordRule: (v) =>
      v == vm.auth.newPassword || "Подтверждение пароля не совпадает",
  }),

  created() {
    if (this.$route.query.logout) this.LOGOUT();
  },

  computed: {
    ...mapGetters("auth", ["GET_PERMISSIONS", "GET_USER"]),
    permissions: (vm) => vm.GET_PERMISSIONS,
  },

  mounted() {
    setTimeout(() => {
      this.isShow = true;

      setTimeout(() => {
        const ss = this.$refs["card-header-wrapper"] || {};
        ss.className += " to-top";
      }, 300);
    }, 200);
  },

  methods: {
    ...mapActions("auth", ["CHANGE_PASSWORD"]),

    async onChangePassword() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;
      await this.CHANGE_PASSWORD(this.auth);
      this.isLoading = false;
    },

    onBack() {
      this.$router.back();
    },
  },
};
</script>

<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card outlined max-width="450" width="100%" class="pa-9">
        <h2 class="mb-10 text-center">Изменить пароль</h2>

        <v-form
          :readonly="isLoading"
          ref="form"
          lazy-validation
          onSubmit="return false;"
        >
          <h4>Текущий пароль</h4>
          <v-text-field
            required
            outlined
            v-model="auth.oldPassword"
            autofocus
            prepend-inner-icon="mdi-key-outline"
            autocomplete="new-password"
            :rules="[$rules.required]"
            :type="isShowPass ? 'text' : 'password'"
            @click:append="isShowPass = !isShowPass"
            @keyup.enter="onChangePassword"
          />

          <h4>Новый пароль</h4>
          <v-text-field
            required
            outlined
            v-model="auth.newPassword"
            :type="isShowPass ? 'text' : 'password'"
            prepend-inner-icon="mdi-lock-outline"
            autocomplete="new-password"
            :rules="[$rules.required]"
            @keyup.enter="onChangePassword"
            @click:append="isShowPass = !isShowPass"
            :append-icon="`mdi-eye${isShowPass ? '-off' : ''}`"
          />

          <h4>Подтвердить пароль</h4>
          <v-text-field
            required
            outlined
            v-model="confirmPassword"
            :type="isShowPass ? 'text' : 'password'"
            prepend-inner-icon="mdi-lock-check-outline"
            autocomplete="new-password"
            :rules="[passwordRule]"
            @keyup.enter="onChangePassword"
            @click:append="isShowPass = !isShowPass"
            :append-icon="`mdi-eye${isShowPass ? '-off' : ''}`"
          />
        </v-form>

        <v-btn
          block
          height="45"
          color="primary"
          :loading="isLoading"
          @click="onChangePassword"
          elevation="0"
        >
          Сохранить
        </v-btn>

        <v-btn
          class="mt-6"
          block
          height="45"
          color="primary"
          :readonly="isLoading"
          @click="onBack"
          outlined
        >
          Отменить
        </v-btn>
      </v-card>
    </v-layout>
  </v-container>
</template>
