<script>
import { mapActions, mapGetters } from "vuex";
const ln = "price_list.items";

export default {
  data: (vm) => ({
    isLoading: false,

    columns: {},

    fields: [
      {
        name: vm.tn("name"),
        value: "productName",
        variants: ["наим", "наз", "тов", "ном"],
        code: "n",
      },
      {
        name: vm.tn("manufacturer"),
        value: "manufacturerName",
        variants: ["про", "чикар", "чиқар"],
        code: "m",
      },
      {
        name: vm.tn("expiry_date"),
        value: "shelfLife",
        variants: ["d", "сро", "дата", "год", "муд"],
      },
      {
        name: vm.tn("product_load"),
        value: "load",
        variants: [],
      },
    ],
  }),

  computed: {
    ...mapGetters("price-list", {
      lineItems: "GET_LINE_ITEMS",
      priceTypes: "GET_PRICE_TYPES",
      priceList: "GET_PRICE_LIST",
    }),

    size: (vm) => vm.$store.getters.GET_SIZE,

    headers() {
      const pi = this.lineItems;
      const lineItems = pi.length ? Object.keys(pi[0]) : [];
      return lineItems.map((x) => ({ text: x, value: x }));
    },

    isSend() {
      const keys = Object.values(this.columns || {});
      const rqs = keys.filter(
        (x) => x == "productName" || x == "manufacturerName"
      );
      const rqsPrice = keys.find((x) => `${x || ""}`.includes("price_"));
      return rqs.length == 2 && rqsPrice;
    },

    availableFields() {
      const values = Object.values(this.columns) || [];

      return this.fields
        .map((x) => {
          x.disabled = !!values.some((v) => v == x.value);
          return x;
        })
        .reverse()
        .sort((a) => (a.disabled ? 1 : -1));
    },
  },

  created() {
    if (this.lineItems?.length) {
      const ts = this.priceTypes.map((x) => {
        const code = `${x.code || ""}`;
        return {
          name: x.name,
          value: "price_$#$" + x.guid,
          variants: code.length ? code.split(" ") : [],
        };
      });
      this.fields = [].concat(this.fields, ts, {
        name: this.tn("code"),
        value: "code",
        variants: [],
      });
      this.checkFields();
    } else {
      this.onExit();
    }
  },

  methods: {
    ...mapActions("price-list", ["ADD_API"]),

    checkFields() {
      this.columns = {};
      const columns = [];
      const values = Object.values(this.lineItems[0]);
      values.forEach((key, inx) => {
        const name = `${key || ""}`.toLowerCase();

        this.fields.forEach((vi) => {
          for (let index = 0; index < vi.variants.length; index++) {
            if (
              ((vi.code && vi.code == name) ||
                name.includes(vi.variants[index])) &&
              !Object.values(columns).find((c) => c == vi.value)
            ) {
              columns[inx] = vi.value;
              break;
            }
          }
        });
      });

      this.$nextTick(() => (this.columns = columns));
    },

    tn(n) {
      return this.$t(ln + "." + n);
    },

    async onSendExcel() {
      if (this.isSend) {
        this.$store.dispatch("SET_LOADING", true);
        this.isLoading = true;
        const status = await this.ADD_API({
          fields: this.columns,
          types: this.priceTypes,
        });
        if (status) {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.tn("saved_alert"),
            color: "success",
          });
          this.onExit();
        } else {
          this.$router.push("/price-list-items-delete");
        }
        this.isLoading = false;
        this.$store.dispatch("SET_LOADING", false);
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("select_columns_alert"),
          color: "info",
        });
      }
    },

    onExit() {
      this.$router.replace("/price-list");
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-items-center pb-4">
      <div class="title">
        {{ priceList._providerName }}
      </div>

      <v-spacer />

      <div class="px-2" />

      <v-btn
        color="primary"
        class="transform-none"
        dark
        @click="onSendExcel"
        elevation="0"
        outlined
        :loading="isLoading"
      >
        {{ tn("save_btn") }}
      </v-btn>

      <div class="px-2" />

      <v-btn
        @click="onExit"
        outlined
        color="red"
        width="36"
        min-width="36"
      >
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </div>

    <v-card
      class="card-wrapper"
      elevation="0"
    >
      <v-data-table
        disable-sort
        :headers="headers"
        hide-default-header
        :items="lineItems"
        :height="size.height - 220"
        :items-per-page="30"
        :footer-props="{ itemsPerPageOptions: [30, 30] }"
        mobile-breakpoint="0"
        fixed-header
        calculate-widths
        dense
      >
        <template v-slot:header="{ props: { headers } }">
          <thead>
            <tr>
              <th
                width="25%"
                v-for="(header, index) in headers"
                :key="index"
              >
                <div class="py-2">
                  <v-select
                    class="table-column-select"
                    clearable
                    item-text="name"
                    item-value="value"
                    hide-details
                    outlined
                    :items="availableFields"
                    v-model="columns[header.value]"
                    dense
                    :menu-props="{
                      maxHeight: 420
                    }"
                  />
                </div>
              </th>
            </tr>
          </thead>
        </template>
        <template v-slot:body="{ items, headers }">
          <tbody v-if="items.length">
            <tr
              v-for="(item, key) in items"
              :key="`tr-${key}`"
            >
              <td
                v-for="(header, index) in headers"
                :key="`td-${index}`"
              >
                <div
                  style="width: 250px"
                  v-text="item[header.value]"
                />
              </td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr class="dt-no-data">
              <td
                class="pt-9"
                :colspan="headers.length"
                v-text="tn('no_data')"
              />
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<style lang="scss" scoped>
.dt-no-data:hover,
.dt-no-data {
  opacity: 0.6;
  text-align: center;
  background-color: transparent !important;
}
</style>
