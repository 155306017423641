<script>
import ModalsTemplate from "../modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    value: {
      type: Object,
    },
  },

  data: (vm) => ({
    dialog: true,

    formData: {
      country: null,
    },

    ln: "manufacturer.filter",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  created() {
    this.formData = { ...this.value };
  },

  methods: {
    async onApply() {
      this.$emit("input", this.formData);
      this.dialog = false;
    },

    async onReset() {
      this.formData = {};
      this.$nextTick(() => this.onApply());
    },
  },
};
</script>

<template>
  <modals-template :width="600" :title="tn('title')" v-model="dialog">
    <AutocompleteBox
      :label="tn('form.country')"
      class="mb-5"
      disableAdd
      :dense="false"
      v-model="formData.country"
      :searchStore="'country'"
      clearable
    />

    <div class="d-flex justify-space-between pt-5">
      <v-btn width="48%" color="error" @click="onReset" elevation="0">
        {{ tn("reset") }}
      </v-btn>
      <v-btn width="48%" color="primary" @click="onApply" elevation="0">
        {{ tn("apply") }}
      </v-btn>
    </div>
  </modals-template>
</template>
