const array = (array) => (Array.isArray(array) ? array : []);

const firstArrayItem = (items) => {
  const arr = array(items);
  return arr.length ? arr[0] : null;
};

const arrayLastItem = (items) => {
  const arr = array(items);

  if (arr.length == 1) {
    return arr[0];
  } else if (arr.length > 1) {
    return arr[arr.length - 1];
  } else return null;
};

const number = (num, def) => {
  const n = Number(num);
  return n > 0 ? n : def || 0;
};
const uuid = () => {
  return Date.now().toString(36) + Math.random().toString(36).substring(2);
};
const upperCaseFirst = (str) => {
  if (!str) return;
  return str.charAt(0).toUpperCase() + str.substring(1);
};
const getSort = (v, dir) => {
  const arr = array(v);
  if (!arr.length) return null;
  if (dir) return arr[0] == true ? "desc" : "asc";
  else return `"${upperCaseFirst(arr[0])}"`;
};

const filterParamsMap = (object) => {
  let params = {};
  Object.keys(object).forEach((key) => {
    if (object[key]?.guid) params[key + "Guid"] = object[key].guid;
    else params[key] = object[key];
  });
  return params;
};

const filterItemsCount = (filterParams) => {
  const filters = Object.values(filterParams).filter(
    (x) => x === 0 || x == false || `${x || ""}`.length
  );
  return filters.length;
};

const popupCenter = ({ url, title, w, h }) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    "/" + url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom}, 
    height=${h / systemZoom}, 
    top=${top}, 
    left=${left}
    `
  );

  if (window.focus) newWindow.focus();
};

const windowOpen = (url, key, name, w, h, params) => {
  url += "/" + encodeURIComponent(key);
  if (params) url += `?${params}`;

  popupCenter({ url, title: name, w, h, params });
};

import Vue from "vue";
Vue.prototype.$uuid = uuid;
Vue.prototype.$array = array;
Vue.prototype.$number = number;
Vue.prototype.$getSort = getSort;
Vue.prototype.$upperCaseFirst = upperCaseFirst;
Vue.prototype.$filterParamsMap = filterParamsMap;
Vue.prototype.$filterItemsCount = filterItemsCount;
Vue.prototype.$windowOpen = windowOpen;
Vue.prototype.$firstArrayItem = firstArrayItem;
Vue.prototype.$arrayLastItem = arrayLastItem;
