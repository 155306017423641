import Vue from "vue";
import VueRouter from "vue-router";
import RouteGuard from "./utils/route-guard";

import Login from "./views/auth/login.vue";
import ChangePassword from "./views/auth/change-password.vue";
import Home from "./views/home.vue";

//Document
import PriceList from "./views/document/price-list.vue";
import PriceListItems from "./views/document/price-list-items.vue";
import PriceListItemsDelete from "./views/document/price-list-items-delete.vue";
import Basket from "./views/document/basket.vue";

//Info
import HomePageTile from "./views/info/home-page-tile.vue";

//CRM
import PriceListProvider from "./views/crm/price-list-provider.vue";
import PriceListProviderUser from "./views/crm/price-list-provider-user.vue";
import PriceListViewer from "./views/crm/price-list-viewer.vue";

// References
import PriceType from "./views/references/price-type.vue";
import Country from "./views/references/country.vue";
import Region from "./views/references/region.vue";
import Brand from "./views/references/brand.vue";
import Product from "./views/references/product.vue";
import InternationalName from "./views/references/international-name.vue";
import Manufacturer from "./views/references/manufacturer.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Login,
  },
  {
    path: "/change-password",
    component: ChangePassword,
    beforeEnter: RouteGuard,
    meta: { auth: true },
  },
  {
    path: "/basket/:viewer/:name",
    component: Basket,
    beforeEnter: RouteGuard,
    meta: { claim: true },
  },
  {
    path: "/home",
    component: Home,
    children: [
      {
        path: "/",
        redirect: "/price-list-provider",
      },
      // Document
      {
        path: "/price-list",
        component: PriceList,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/price-list-items",
        component: PriceListItems,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/price-list-items-delete",
        component: PriceListItemsDelete,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      // Info
      {
        path: "/home-page-tile",
        component: HomePageTile,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      // CRM
      {
        path: "/price-list-provider",
        component: PriceListProvider,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/price-list-provider-user",
        component: PriceListProviderUser,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/price-list-viewer",
        component: PriceListViewer,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/baskets",
        component: Basket,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },

      // References
      {
        path: "/price-type",
        component: PriceType,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/country",
        component: Country,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/region",
        component: Region,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/brand",
        component: Brand,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/product",
        component: Product,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/international-name",
        component: InternationalName,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
      {
        path: "/manufacturer",
        component: Manufacturer,
        beforeEnter: RouteGuard,
        meta: { claim: true },
      },
    ],
  },
  {
    path: "*",
    redirect: "/",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
