import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;
let cancelSource2 = null;

export const state = () => ({
  itemsData: {
    items: [],
    total: 0,
  },
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    };
  },
};

export const actions = {
  async GET_API({ commit, }, { options, filterParams, }) {
    cancelSource2?.cancel();
    cancelSource2 = CancelToken.source();

    commit("SET_ITEMS");
    const params = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      search: options.search,
      sortBy: options.sort_by,
      sortDirection: options.sort_direction,
      ...filterParams,
    };

    const request = { progress: false, cancelToken: cancelSource2.token, };
    const res = await vm.$axios.$get("brand", params, request);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async SEARCH(ctx, { value, }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    const search = `${value || ""}`;
    if (search.length < 2) return [];

    const request = { progress: false, cancelToken: cancelSource.token, };
    const res = await vm.$axios.$get("brand/lookUp", { search, }, request);
    return vm.$array(res?.data?.items);
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
};
