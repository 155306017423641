import Vue from "vue";

const resizeTablePlugin = {
  install(Vue) {
    Vue.directive("resize-table", {
      bind(el, binding) {
        resizeTable(el, binding.value);
      },
    });
  },
};

Vue.use(resizeTablePlugin);

function resizeTable(table, tableHeight) {
  const row = table.getElementsByTagName("tr")[0];
  const cols = row.children || undefined;
  if (!cols) return;

  for (let i = 0; i < cols.length; i++) {
    if (cols[i].classList.contains("resize")) {
      let div = createDiv(tableHeight);
      cols[i].appendChild(div);
      setListeners(div, table);
    }
  }
}

function setListeners(div, table) {
  let pageX, curCol, nxtCol, curColWidth, nxtColWidth;

  div.addEventListener("mousedown", function (e) {
    curCol = e.target.parentElement;
    nxtCol = curCol.nextElementSibling;
    pageX = e.pageX;
    let padding = paddingDiff(curCol);

    curColWidth = curCol.offsetWidth - padding;
    if (nxtCol) nxtColWidth = nxtCol.offsetWidth - padding;
  });

  table.addEventListener("mousemove", function (e) {
    if (curCol) {
      let diffX = e.pageX - pageX;
      if (nxtCol) nxtCol.style.width = nxtColWidth - diffX + "px";
      curCol.style.width = curColWidth + diffX + "px";
    }
  });

  document.addEventListener("mouseup", function () {
    curCol = undefined;
    nxtCol = undefined;
    pageX = undefined;
    nxtColWidth = undefined;
    curColWidth = undefined;
  });
}

function createDiv(height) {
  let div = document.createElement("div");
  div.className = "col-resize";
  div.style.height = height + "px";
  return div;
}

function paddingDiff(col) {
  if (getStyleVal(col, "box-sizing") == "border-box") return 0;
  let padLeft = getStyleVal(col, "padding-left");
  let padRight = getStyleVal(col, "padding-right");
  return parseInt(padLeft) + parseInt(padRight);
}

function getStyleVal(elm, css) {
  return window.getComputedStyle(elm, null).getPropertyValue(css);
}
