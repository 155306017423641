import Vue from "vue";
const vm = Vue.prototype;

const url = "PriceList";

export const state = () => ({
  columns: [],
  priceList: {},
});

export const mutations = {
  SET_LINE_ITEMS(state, payload) {
    const { priceList, columns } = payload || {};
    state.columns = vm.$array(columns);
    state.priceList = priceList || {};
  },
};

export const actions = {
  async SET_LINE_ITEMS({ commit }, payload) {
    const lineItems = payload.priceList.lineItems.map((item) => {
      item.prices.forEach((price) => {
        item["price_" + price.typeGuid] = price.amount;
      });
      return item;
    });

    const items = [];
    const errors = [];

    lineItems.forEach((item) => {
      const keys = Object.keys(item).filter((x) =>
        `${x || ""}`.startsWith("price_")
      );

      for (let index = 0; index < keys.length; index++) {
        const key = keys[index];

        if (typeof item[key] != "number") {
          item.invalid = true;
          errors.push(item);
          break;
        }

        if (index == keys.length - 1) {
          items.push(item);
        }
      }
    });

    payload.priceList.lineItems = [...errors, ...items];
    commit("SET_LINE_ITEMS", payload);
  },

  async SAVE_PRICE_LIST({ commit, state }) {
    const params = JSON.parse(JSON.stringify(state.priceList));
    params.lineItems = params.lineItems
      .filter((x) => !x.invalid)
      .map((item) => {
        const keys = Object.keys(item).filter((x) =>
          `${x || ""}`.startsWith("price_")
        );

        keys.forEach((key) => {
          delete item[key];
        });

        return item;
      });

    const res = await vm.$axios.$post(url, params);
    if (res?.succeeded) commit("SET_LINE_ITEMS");
    return res?.succeeded;
  },
};

export const getters = {
  GET_PRICE_LIST: (state) => state.priceList,
  GET_COLUMNS: (state) => state.columns,
};
