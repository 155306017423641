<script>
import { mapActions, mapGetters } from "vuex";

import ModalsTemplate from "../../modals-template.vue";
import FieldTextVariants from "../../field-text-variants.vue";
import AutocompleteRegion from "./autocomplete-region.vue";

export default {
  components: { ModalsTemplate, FieldTextVariants, AutocompleteRegion },

  props: {
    editData: {
      type: Object,
    },
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,
    isIniting: false,

    formData: {
      name: null,
      description: null,
      address: null,
      phone: null,
      bankInfo: null,
      email: null,
      inn: null,
      telegramPhoneNumber: null,
      priceListValidDaysCount: null,
      minAmountToPurchase: null,
      minAmountToPurchaseWithDelivery: null,
      regions: null,
      status: 1,
      isPublic: true,
      isAvailableBasketToOrder: false,
      priceListViewerPhone: null
    },

    phones: [],
    phoneModel: [],

    ln: "price_list_provider.add",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  computed: {
    ...mapGetters("price-list-provider", ["GET_STATUSES"]),
    ...mapGetters("region", {
      regions: "GET_REGIONS",
    }),
    statuses: (vm) => vm.GET_STATUSES,
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions("price-list-provider", [
      "SEARCH",
      "ADD_API",
      "GET_BY_GUID_API",
      "INIT_STATUSES",
    ]),
    ...mapActions("region", ["GET_REGIONS_API"]),

    async init() {
      this.isIniting = true;
      if (!this.statuses?.length) {
        this.$store.dispatch("SET_LOADING", true);
        await this.INIT_STATUSES();
        if (!this.regions.length) await this.GET_REGIONS_API();
        this.$store.dispatch("SET_LOADING", false);
      }

      if (this.editData?.guid) {
        this.$store.dispatch("SET_LOADING", true);
        const data = await this.GET_BY_GUID_API(this.editData);
        if (data) {
          if (data.phone) this.phoneModel = data.phone.split(", ");
          this.formData = data;
        }
        this.$store.dispatch("SET_LOADING", false);
      }
      this.$nextTick(() => (this.isIniting = false));
    },

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const formData = { ...this.formData };

        if (formData.regions?.length) {
          formData.regions = formData.regions.map((x) => x.guid);
        }

        if (this.phoneModel.length) formData.phone = this.phoneModel.join(", ");
        const data = await this.ADD_API(formData);
        this.isLoading = false;
        if (data) {
          this.$emit("saved", data);
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template
    v-if="!isIniting"
    :width="1000"
    :title="tn('title')"
    v-model="dialog"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-row>
        <v-col cols="6">
          <div>
            {{ tn("form.name") }}
          </div>
          <v-text-field
            autofocus
            outlined
            v-model="formData.name"
            @keyup.enter="onSave"
            dense
            required
            :rules="[$rules.required]"
            hide-details
          />

          <div class="pb-2" />

          <field-text-variants
            :height="70"
            :object="formData"
            :searchProp="SEARCH"
          />
        </v-col>
        <v-col cols="6">
          <div>
            {{ tn("form.status") }}
          </div>
          <v-select
            item-text="name"
            item-value="id"
            :items="statuses"
            outlined
            v-model="formData.status"
            dense
            hide-details
          />

          <div class="pb-3" />

          <div>
            {{ tn("form.address") }}
          </div>
          <v-textarea
            outlined
            v-model="formData.address"
            dense
            rows="2"
            required
            :rules="[$rules.required]"
            hide-details
          />
        </v-col>
        <v-col cols="12">
          <AutocompleteRegion
            :label="tn('form.regions')"
            v-model="formData.regions"
            :items="regions"
            required
          />
        </v-col>
        <v-col cols="12">
          <div>
            {{ tn("form.phone") }}
          </div>
          <v-combobox
            v-model="phoneModel"
            :items="phones"
            hide-selected
            multiple
            dense
            outlined
            required
            small-chips
            deletable-chips
            :rules="[$rules.array]"
            clearable
            hide-details
            hide-no-data
          />
        </v-col>
        <v-col cols="4">
          <div>
            {{ tn("form.inn") }}
          </div>
          <v-text-field
            outlined
            v-model="formData.inn"
            dense
            hide-details
            v-inputmask="$masks.number_only"
          />
        </v-col>
        <v-col cols="4">
          <div>
            {{ tn("form.telegramPhoneNumber") }}
          </div>
          <v-text-field
            outlined
            v-model="formData.telegramPhoneNumber"
            dense
            hide-details
            v-inputmask="$masks.number_only"
          />
        </v-col>
        <v-col cols="4">
          <div>
            {{ tn("form.email") }}
          </div>
          <v-text-field
            outlined
            v-model="formData.email"
            dense
            hide-details
          />
        </v-col>

        <v-col cols="12">
          <div>
            {{ tn("form.bankInfo") }}
          </div>
          <v-textarea
            rows="1"
            outlined
            v-model="formData.bankInfo"
            dense
            hide-details
          />
        </v-col>

        <v-col cols="4">
          <div>
            {{ tn("form.priceListValidDaysCount") }}
          </div>
          <v-text-field
            outlined
            v-model="formData.priceListValidDaysCount"
            dense
            hide-details
            v-inputmask="$masks.number"
            required
            :rules="[$rules.required]"
          />
        </v-col>
        <v-col cols="4">
          <div>
            {{ tn("form.minAmountToPurchase") }}
          </div>
          <v-text-field
            outlined
            v-model="formData.minAmountToPurchase"
            dense
            hide-details
            v-inputmask="$masks.number"
          />
        </v-col>
        <v-col cols="4">
          <div>
            {{ tn("form.minAmountToPurchaseWithDelivery") }}
          </div>
          <v-text-field
            outlined
            v-model="formData.minAmountToPurchaseWithDelivery"
            dense
            hide-details
            v-inputmask="$masks.number"
          />
        </v-col>

        <v-col cols="4">
          <v-checkbox
            hide-details
            :label="tn('form.is_available_basket_to_order')"
            v-model="formData.isAvailableBasketToOrder"
          />
        </v-col>

        <v-col cols="4">
          <v-checkbox
            hide-details
            :label="tn('form.is_public')"
            v-model="formData.isPublic"
          />
        </v-col>

        <v-col cols="4">
          <div>
            {{ tn("form.price_list_viewer_phone") }}
          </div>
          <v-text-field
            outlined
            v-model="formData.priceListViewerPhone"
            dense
            hide-details
            v-inputmask="$masks.number"
          />
        </v-col>

        <v-col cols="12">
          <v-textarea
            :label="tn('form.description')"
            outlined
            v-model="formData.description"
            dense
            rows="1"
            hide-details
          />
        </v-col>
      </v-row>

      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
