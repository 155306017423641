<script>
import { debounce } from "debounce";

export default {
  props: {
    object: { type: Object, required: true },
    searchProp: { type: Function, required: true },
    height: { type: [Number, String], default: () => 250 },
    isProduct: Boolean,
  },

  data: () => ({
    entries: [],
    isLoading: false,
    searchedValue: null,
  }),

  watch: {
    [`object.name`](v) {
      this.onSearch(v);
    },
    [`object.code`](v) {
      this.onSearch(v);
    },
    [`object.nameRu`](v) {
      this.onSearch(v);
    },
  },

  created() {
    const value = this.object?.name || this.object?.code || this.object?.nameRu;
    if (value) this.onSearch(value);
  },

  methods: {
    onSearch: debounce(async function (value) {
      this.isLoading = true;
      this.entries = await this.searchProp({ value });
      this.isLoading = false;
    }, 200),

    async onSelect(item) {
      if (item?.guid) {
        this.$emit("select", item);
      }
    },
  },
};
</script>

<template>
  <div>
    <div>{{ $t("double_check_box_info") }}</div>
    <v-card elevation="0" :height="height" outlined class="list-wrap pa-3">
      <div v-if="isLoading" class="loader-wrap">
        <div>
          <v-progress-linear indeterminate />
        </div>
      </div>
      <div v-else-if="entries.length">
        <div
          class="pb-2"
          :class="isProduct && 'cursor-pointer'"
          @click="onSelect(item)"
          :title="item.name"
          v-for="item in entries"
          :key="item.guid"
          v-text="item.name"
        />
      </div>
    </v-card>
  </div>
</template>

<style scoped lang="scss">
.list-wrap {
  overflow-y: auto;
}

.loader-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  > div {
    max-width: 80%;
    width: 100%;
  }
}
</style>
