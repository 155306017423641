import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;
let cancelSource2 = null;

const url = "PriceListProviderUser"

export const state = () => ({
  itemsData: {
    items: [],
    total: 0,
  },
  statuses: []
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    };
  },
  SET_STATUSES(state, payload) {
    state.statuses = vm.$array(payload)
  },
};

export const actions = {
  async GET_API({ commit, }, { options, filterParams = {}, }) {
    cancelSource2?.cancel();
    cancelSource2 = CancelToken.source();

    commit("SET_ITEMS");
    const params = {
      providerGuid: filterParams.providerGuid,
      status: filterParams.status,
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      search: options.search,
      sortBy: options.sort_by,
      sortDirection: options.sort_direction,
    };
    const request = { progress: false, cancelToken: cancelSource2.token, };
    const res = await vm.$axios.$get(`${url}`, params, request);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async INIT_STATUSES({ commit }) {
    const res = await vm.$axios.$get(`${url}/StatusLookUp`);
    if (res?.succeeded) commit("SET_STATUSES", res?.data);
  },

  async ADD_API(ctx, payload) {
    if (payload.guid) {
      const params = {
        guid: payload.guid,
        phone: payload.phone,
        contactPhone: payload.contactPhone,
        firstName: payload.firstName,
        lastName: payload.lastName,
        status: payload.status,
      }
      const res = await vm.$axios.$put(url, params);
      if (res?.succeeded) {
        return { name: payload.firstName, guid: res.data }
      }
    } else {
      const res = await vm.$axios.$post(url, payload);
      if (res?.succeeded) {
        return { name: payload.firstName, guid: res.data }
      }
    }
  },

  async REMOVE_API(ctx, payload) {
    const res = await vm.$axios.$post(url, payload);
    return res?.succeeded;
  },

  async BIND_API(ctx, payload) {
    const res = await vm.$axios.$post('PriceListProviderUserProvider', payload);
    return res?.succeeded;
  },

  async UN_BIND_API(ctx, payload) {
    const res = await vm.$axios.$post('PriceListProviderUserProvider/Delete', payload);
    return res?.succeeded;
  },

  async SEARCH(ctx, { value, providerGuid }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    const search = `${value || ""}`;
    if (search.length < 2) return [];

    const request = { progress: false, cancelToken: cancelSource.token, };
    const res = await vm.$axios.$get(`${url}/lookUp`, { search, providerGuid }, request);
    return vm.$array(res?.data?.items);
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
  GET_STATUSES: (state) => state.statuses,
};
