<script>
import { mapActions } from "vuex";
import ModalsTemplate from "../../modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    value: Object,
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,

    formData: { ...vm.value },

    phones: [],
    phoneModel: [],

    ln: "price_list_viewer.set_type",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    ...mapActions("price-list-viewer", ["SET_TYPE"]),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const formData = { ...this.formData };
        const data = await this.SET_TYPE(formData);
        this.isLoading = false;
        if (data) {
          this.$emit("success", data);
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template :width="500" :title="tn('title')" v-model="dialog">
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-checkbox
        class="mt-0"
        hide-details
        :label="tn('form.is_staff')"
        v-model="formData.isStaff"
      />
      <v-checkbox
        hide-details
        :label="tn('form.is_pharmacy')"
        v-model="formData.isPharmacy"
      />
      <v-checkbox
        hide-details
        :label="tn('form.is_provider')"
        v-model="formData.isProvider"
      />

      <v-btn
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        depressed
        class="mt-5 mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
