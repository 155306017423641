<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import TableSort from "@/components/table-sort.vue";
import PriceListViewerFilter from "@/components/modals/crm/price-list-viewer-filter.vue";
import PriceListViewerType from "@/components/modals/crm/price-list-viewer-type.vue";

export default {
  components: {
    ConfirmDialog,
    TableSort,
    PriceListViewerFilter,
    PriceListViewerType,
  },

  data: (vm) => ({
    options: {},
    page: 1,
    itemsPerPage: 50,

    isFilter: false,

    isLoading: false,

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    filterParams: {},

    isInit: false,
    isShowType: false,
    isPermissionOrder: false,

    selected: {},

    ln: "price_list_viewer",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("price-list-viewer", ["GET", "GET_COUNT", "GET_STATUSES"]),
    size: (vm) => vm.$store.getters.GET_SIZE,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    headers: (vm) => [
      {
        text: "#",
        value: "number",
        width: 40,
        align: "center",
      },
      {
        text: vm.tn("table.name"),
        value: "storeName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.status"),
        value: "status",
        _filter: (v) => vm.GET_STATUSES.find((x) => x.id == v)?.name,
      },
      {
        text: vm.tn("table.address"),
        value: "address",
        minWidth: 200,
      },
      {
        text: vm.tn("table.phone"),
        value: "phone",
      },
      {
        text: vm.tn("table.contactPhone"),
        value: "contactPhone",
      },
      {
        text: vm.tn("table.inn"),
        value: "inn",
        _filter: (v) => v,
      },
      {
        text: vm.tn("table.is_staff"),
        value: "isStaff",
      },
      {
        text: vm.tn("table.is_pharmacy"),
        value: "isPharmacy",
      },
      {
        text: vm.tn("table.is_provider"),
        value: "isProvider",
      },
      {
        text: vm.tn("table.firstName"),
        value: "firstName",
      },
      {
        text: vm.tn("table.lastName"),
        value: "lastName",
      },
      {
        text: vm.tn("table.validDateFrom"),
        value: "validDateFrom",
        _filter: (v) => vm.$date(v),
      },
      {
        text: vm.tn("table.validDateTill"),
        value: "validDateTill",
        _filter: (v) => vm.$date(v),
      },
      {
        text: vm.tn("table.lastLoginDateTime"),
        value: "lastLoginDateTime",
        _filter: (v) => vm.$dateTime(v),
      },
      {
        text: vm.tn("table.can_use_order"),
        value: "canUseOrder",
      },

      ...vm.$baseTableColumns(vm),
      { text: "", value: "actions", width: 10, align: "center" },
    ],

    sortItems: (vm) =>
      vm.headers
        .filter(
          (x) => x.value != "actions" && x.value != "number" && x._sort != false
        )
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),
  },

  created() {
    const q = this.$route.query;
    const sq = this.$browserStore.getSession("tb" + this.$route.path) || {};
    const page = q.page || sq.page;
    const per_page = q.per_page || sq.per_page;
    this.page = this.$number(page) || 1;
    this.itemsPerPage = this.$number(per_page) || 50;

    this.init();
  },

  methods: {
    ...mapActions("price-list-viewer", [
      "GET_API",
      "INIT_STATUSES",
      "REMOVE_API",
      "PERMISSION_ORDER_API",
    ]),

    async init() {
      this.$store.dispatch("SET_LOADING", true);
      await this.INIT_STATUSES();
      this.$store.dispatch("SET_LOADING", false);
      this.isInit = true;
    },

    async getEntries() {
      this.isLoading = true;
      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;

      const filterParams = this.$filterParamsMap(this.filterParams);
      const options = { options: { ...this.options }, filterParams };

      await this.GET_API(options);
      this.isLoading = false;
      this.setRoute();
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    setRoute() {
      const query = { page: this.options.page, per_page: this.itemsPerPage };
      const page = this.$number(this.$route.query.page);
      const per_page = this.$number(this.$route.query.per_page);
      if (query.page != page || query.per_page != per_page) {
        this.$router.replace({ query });
        this.$browserStore.setSession("tb" + this.$route.path, query);
      }
    },

    onFilter(v) {
      this.filterParams = v;
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onOpenBasket(item) {
      const url = `/basket/${item.guid}/${encodeURIComponent(item.storeName)}`;
      const width = window.innerWidth;
      const height = window.innerHeight;
      window.open(url, item.storeName, `width=${width},height=${height}`);
    },

    onShowSetType({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isShowType = true));
    },

    onShowPermissionOrder({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isPermissionOrder = true));
    },

    async onPermissionOrder({ close, setLoading }) {
      setLoading(true);
      const params = { priceListViewerGuid: this.selected.guid };
      await this.PERMISSION_ORDER_API(params);
      this.getEntries();
      setLoading(false);
      close();
    },
  },
};
</script>

<template>
  <div>
    <confirm-dialog
      :text="tn('permission_order_alert')"
      @close="isPermissionOrder = false"
      v-if="isPermissionOrder"
      @accept="onPermissionOrder"
    />

    <price-list-viewer-filter
      :value="filterParams"
      @input="onFilter"
      v-if="isFilter"
      :statuses="GET_STATUSES"
      @close="isFilter = false"
    />

    <price-list-viewer-type
      :value="{
        guid: selected.guid,
        isStaff: selected.isStaff,
        isPharmacy: selected.isPharmacy,
        isProvider: selected.isProvider,
      }"
      v-if="isShowType"
      @success="getEntries"
      @close="isShowType = false"
    />

    <div class="d-flex align-items-center pb-4">
      <h3>{{ tn("title") }}</h3>

      <v-spacer />

      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <v-badge
        dot
        overlap
        color="error"
        :value="$filterItemsCount(filterParams)"
      >
        <v-btn
          :title="tn('filter_btn')"
          @click="isFilter = true"
          outlined
          color="primary"
          min-width="38"
          width="38"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-badge>

      <div class="px-2" />

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          append-icon="mdi-magnify"
          @click:clear="onClear"
          clearable
        />
      </div>
    </div>

    <v-data-table
      v-if="isInit"
      :height="size.height - 220"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
      class="fixed-right"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td v-else-if="head._filter" :key="head.value">
                <div
                  v-text="head._filter(item[head.value])"
                  class="text-nowrap"
                />
              </td>

              <td
                v-else-if="head.value == 'actions'"
                :key="head.value"
                class="px-0"
              >
                <div class="d-flex tb-action-wrap">
                  <v-btn
                    :title="tn('table.actions.show_set_type')"
                    @click="onShowSetType({ item })"
                    icon
                  >
                    <v-icon> mdi-account-cog-outline </v-icon>
                  </v-btn>

                  <v-btn
                    :title="tn('table.actions.show_cart')"
                    @click="onOpenBasket(item)"
                    icon
                  >
                    <v-icon>mdi-cart-variant</v-icon>
                  </v-btn>

                  <v-btn
                    :title="tn('table.actions.can_use_order')"
                    @click="onShowPermissionOrder({ item })"
                    icon
                  >
                    <v-icon>mdi-key-outline</v-icon>
                  </v-btn>
                </div>
              </td>

              <td v-else :key="head.value">
                <div
                  :style="{ minWidth: head.minWidth + 'px' }"
                  v-text="item[head.value]"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
