<script>
import { mapActions } from "vuex";

import ModalsTemplate from "../../modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    editData: {
      type: Object,
    },
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,

    formData: {
      name: null,
    },

    ln: "price_list_provider.bind",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    ...mapActions("price-list-provider-user", ["BIND_API"]),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;

        const params = {};
        params.providerGuid = this.editData.guid;
        params.providerUserGuid = this.formData?.providerUserGuid?.guid;

        const data = await this.BIND_API(params);
        this.isLoading = false;
        if (data) {
          this.$emit("saved", data);
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template :width="500" :title="tn('add_provider')" v-model="dialog">
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-row>
        <v-col cols="12">
          <AutocompleteBox
            :label="tn('form.provider')"
            v-model="formData.providerUserGuid"
            :searchStore="'price-list-provider-user'"
            autofocus
            clearable
          />
        </v-col>
      </v-row>

      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
