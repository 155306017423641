<script>
export default {
  props: {
    background: String,
    icon: String,
    nameRu: String,
    nameUz: String,
    nameUzLat: String,
    count: [String, Number],
    url: String,
  },

  data: (vm) => ({
    navbar: true,
    ln: "home_page_tile.card",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  methods: {
    onOpen(url) {
      window.open(url);
    },
  },
};
</script>

<template>
  <div class="card-wrap">
    <div class="card" :style="{ background }">
      <div class="card-actions-wrap">
        <div class="card-actions">
          <v-btn @click="$emit('edit')" :title="tn('actions.edit')" small icon>
            <v-icon> mdi-pencil </v-icon>
          </v-btn>

          <v-btn
            :title="tn('actions.delete')"
            @click="$emit('delete')"
            class="ml-1"
            small
            icon
          >
            <v-icon> mdi-delete </v-icon>
          </v-btn>
        </div>
      </div>

      <div class="card-content">
        <div>
          <h1>{{ count }}</h1>
          Ru <b> {{ nameRu }} </b>
          <br />
          Uz <b> {{ nameUz }} </b>
          <br />
          Uz-lat <b> {{ nameUzLat }} </b>
          <br />
          <a v-if="url" :href="url" class="inherit--text" target="_blank">
            <small>{{ tn("open_link") }}</small>
          </a>
        </div>
        <div>
          <v-icon :size="90" dark>
            {{ icon }}
          </v-icon>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.card-wrap {
  display: flex;
  justify-content: center;
  color: white;

  .card {
    position: relative;
    overflow: hidden;

    border-radius: 3px;
    flex: 1;

    .card-content {
      margin-top: 10px;
      padding: 20px;
      display: flex;
      justify-content: space-between;
    }

    .card-actions-wrap {
      .card-actions {
        position: absolute;
        visibility: hidden;
        top: 0;
        right: 0;
        border-bottom-left-radius: 10px;

        padding-left: 3px;
        transition: all 0.1s ease-out;
        transform: translate(40px, -40px);

        .theme--light & {
          background: white;
        }

        .theme--dark & {
          background: #1a2035;
        }
      }
    }

    &:hover .card-actions {
      visibility: visible;
      transform: translate(0, 0);
    }
  }
}
</style>
