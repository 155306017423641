<script>
import DateBox from "@/components/fields/date-box.vue";
import { mapActions } from "vuex";
import ModalsTemplate from "../../modals-template.vue";

export default {
  components: { ModalsTemplate, DateBox },

  data: (vm) => ({
    dialog: true,
    isLoading: false,

    formData: {
      providerGuid: null,
      documentDate: new Date().toJSON().substring(0, 10),
    },

    ln: "price_list.add",
    tn: (n) => vm.$t(vm.ln + "." + n),

    avFields: ["provider"],

    excelFile: null,
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  methods: {
    ...mapActions("price-list", ["SET_LINE_ITEMS"]),

    async onSave() {
      if (this.$refs.form.validate() && this.formData.documentDate) {
        this.isLoading = true;
        const formData = { ...this.formData };
        const priceList = this.$filterParamsMap(formData);
        priceList._providerName = formData?.provider?.name;

        const lineItems = await this.$readExcelData(this.excelFile);
        if (lineItems?.length) {
          const status = await this.SET_LINE_ITEMS({ lineItems, priceList });
          if (status) {
            this.dialog = false;
            this.$nextTick(() => this.$emit("saved"));
          }
        } else {
          this.$store.dispatch("SET_SNACKBAR", {
            text: this.tn("no_excel_data_alert"),
            color: "info",
          });
        }

        this.isLoading = false;
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template :width="600" :title="tn('title')" v-model="dialog">
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <AutocompleteBox
        autofocus
        clearable
        :label="tn('form.provider')"
        v-model="formData.provider"
        :searchStore="'price-list-provider'"
        required
      />

      <div class="pt-5" />

      <DateBox
        :label="tn('form.documentDate')"
        v-model="formData.documentDate"
        clearable
        required
        :rules="[$rules.required]"
        hide-details
        dense
      />

      <div class="pt-5" />

      <v-file-input
        :label="tn('form.excelFile')"
        v-model="excelFile"
        clearable
        hide-details
        dense
        required
        :rules="[$rules.required]"
        outlined
        prepend-icon=""
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
      />

      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
