export default (vm) => [
  {
    text: vm.$t("base_table.create_time"),
    value: "createdOn",
    _filter: (v) => vm.$dateTime(v),
    width: 150,
  },
  {
    text: vm.$t("base_table.who_created"),
    value: "createdBy",
    width: 150,
  },
  {
    text: vm.$t("base_table.update_time"),
    value: "modifiedOn",
    _filter: (v) => vm.$dateTime(v),
    width: 150,
  },
  {
    text: vm.$t("base_table.who_update"),
    value: "modifiedBy",
    width: 150,
  },
]
