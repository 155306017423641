<script>
import { mapActions, mapGetters } from "vuex";

const rqDtMs = (x) => ["view_store-items"].find((r) => x.startsWith(r));
const dtMs = (x) => ["view_all-store-items"].find((r) => x.startsWith(r));

export default {
  data: () => ({
    isLoading: false,
    isShowPassword: false,
    auth: {
      username: null,
      password: null,
    },
  }),

  computed: {
    ...mapGetters("auth", ["GET_CLAIMS", "GET_USER"]),
  },

  methods: {
    ...mapActions("auth", ["LOGIN"]),

    async onLogin() {
      if (!this.$refs.form.validate()) return;
      this.isLoading = true;
      const status = await this.LOGIN(this.auth);

      if (status) {
        if (this.GET_USER.isSuperUser) {
          this.$router.push("/home");
        } else {
          const scms = this.GET_CLAIMS;
          const ur = scms.find((x) => x.startsWith("view_clientApp"));
          if (ur) this.$router.push(ur.replace("view_", ""));
          else {
            const claims = scms.filter(
              (x) => x.startsWith("view_") && !rqDtMs(x)
            );

            if (claims.length) {
              let stUrl = claims.find((x) => dtMs(x)) || claims[0];
              this.$router.push(stUrl.replace("view_", ""));
            } else {
              this.$store.dispatch("SET_SNACKBAR", {
                text: this.$t("dont_access"),
                color: "info",
              });
            }
          }
        }
      }
      this.isLoading = false;
    },
  },
};
</script>

<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-card outlined max-width="450" width="100%" class="pa-9">
        <h2 class="mb-10 text-center">Редактор прайс-листов</h2>
        <v-form :readonly="isLoading" ref="form" lazy-validation>
          <h4>Логин</h4>
          <v-text-field
            autofocus
            required
            outlined
            prepend-inner-icon="mdi-account"
            name="username"
            :rules="[$rules.required]"
            type="text"
            v-model="auth.username"
            @keyup.enter="onLogin"
            class="-input"
          />

          <h4>Пароль</h4>
          <v-text-field
            class="-input"
            required
            prepend-inner-icon="mdi-lock"
            :append-icon="`mdi-eye${isShowPassword ? '-off' : ''}`"
            @click:append="isShowPassword = !isShowPassword"
            name="password"
            :rules="[$rules.required]"
            :type="isShowPassword ? 'text' : 'password'"
            v-model="auth.password"
            @keyup.enter="onLogin"
            outlined
          />
        </v-form>

        <v-btn
          block
          height="45"
          color="primary"
          @click="onLogin"
          :loading="isLoading"
          elevation="0"
        >
          Войти в систему
        </v-btn>
      </v-card>
    </v-layout>

    <div class="version-wrap">версия: {{ $version }}</div>
  </v-container>
</template>

<style lang="scss" scoped>
.-input {
  border-radius: 8px !important;
}

.version-wrap {
  position: fixed;
  bottom: 5px;
  font-size: 11px;
  left: 5px;
}
</style>
