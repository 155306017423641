<script>
export const createMixin = (path) => ({
  data: () => ({
    isAccessAdd: false,
    isAccessEdit: false,
    isAccessDelete: false,
  }),

  created() {
    this.isAccessAdd = this.$access("add", path);
    this.isAccessEdit = this.$access("edit", path);
    this.isAccessDelete = this.$access("delete", path);
  },
});

export default createMixin();
</script>
