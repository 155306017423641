import Vue from "vue";
const vm = Vue.prototype;
import router from "@/router";

export const state = () => ({
  user: {},
  claims: [],
});

export const mutations = {
  SET_USER(state, user) {
    user = user || {};
    state.claims = vm.$array(user.claims).filter((x) => `${x || ""}`.trim());
    delete user.claims;
    user.exist = true;
    state.user = user;
  },

  LOGOUT(state) {
    state.user = {};
    state.claims = [];
    vm.$cookies.remove("token");
    vm.$cookies.remove("user");
  },
};

export const actions = {
  async INIT_USER({ commit, }) {
    const res = await vm.$axios.$get("account/me");
    if (res?.succeeded) commit("SET_USER", res.data);
  },

  async LOGIN({ dispatch, }, auth) {
    const res = await vm.$axios.$post("account/token", auth);
    const status = res?.succeeded;
    if (status) {
      vm.$cookies.set("token", res.data.token);
      await dispatch("INIT_USER");
      await dispatch("INIT_STATES", null, { root: true, });
    }

    return status;
  },

  async CHANGE_PASSWORD({ dispatch, }, auth) {
    const res = await vm.$axios.$post("account/changePassword", auth);
    let status = res?.succeeded;
    if (status) {
      const text = "Пароль успешно изменен!";
      dispatch("SET_SNACKBAR", { text, color: "success", }, { root: true, });
      if (!router.back()) router.push("/products");
    }
    return status;
  },

  LOGOUT({ commit, }) {
    commit("LOGOUT");
    router.replace("/");
  },
};

export const getters = {
  GET_USER: (state) => state.user,
  GET_CLAIMS: (state) => state.claims,
};
