import Vue from "vue";
const vm = Vue.prototype;

import moment from "moment";
import parseFormat from "moment-parseformat";

const currentYear = _getCurrentYear();
function _getCurrentYear() {
  const y = new Date().getFullYear();
  return y > 2022 ? y : 2022;
}

const months = [
  "янв",
  "фев",
  "мар",
  "апр",
  "ма[яйи]",
  "июн",
  "июл",
  "авг",
  "сен",
  "окт",
  "но[йя]",
  "дек",
];

const monthRegex = new RegExp(`^(${months.join("|")})`, "i");

// const dates = [
//   "1212",
//   "3212",
//   "г.",
//   "1122",
//   "2022/02/10",
//   "01.07.2024",
//   "01.02.27",
//   "12.2026",
//   "Октябрь 24 г.",
//   " 06.24",
//   "фев.24",
// ];

// setTimeout(() => {
//   console.log("Read", "<->", "Excel");
//   dates.forEach((value) => {
//     tryParseDate(value, (res) => {
//       if (new Date(res).getFullYear() < currentYear) res = null;
//       console.log(res || "-", "<->", value);
//     });
//   });
// }, 1000);

export const parseDateAsync = async (value) => {
  return new Promise((resolve) => {
    tryParseDate(value, (res) => {
      if (new Date(res).getFullYear() < currentYear) res = null;
      resolve(res);
    });
  });
};

function tryParseDate(date_df, callback) {
  date_df = `${date_df || ""}`;
  if (date_df.length < 2) return callback(null);
  date_df = date_df.toLocaleLowerCase();
  const number = date_df.replace(/[-,.-/- ]/g, "");
  const string = date_df.replace(/[.,-/- ]/g, "-");

  if (vm.$number(number)) {
    if (number.length == 8) {
      if (date_df.length == 10) {
        const format = parseFormat(date_df);
        const resMoment = moment(date_df, format, "ru");
        const res = resMoment.isValid() ? resMoment.format("YYYY-MM-DD") : null;
        return callback(res);
      } else {
        const resMoment = moment(date_df, "DDMMYYYY", "ru");
        const res = resMoment.isValid() ? resMoment.format("YYYY-MM-DD") : null;
        return callback(res);
      }
    } else if (number.length == 4) {
      const mm = number.substring(0, 2);
      if (mm > 12 && number >= currentYear) {
        return callback(`${number}-01-01`);
      } else {
        const yy = number.substring(2, 4);
        const y = "20" + yy;
        if (y >= currentYear) return callback(`${y}-${mm}-01`);
      }
    } else if (number.length == 2) {
      if (number <= 12) {
        return callback(`${currentYear}-${number}-01`);
      } else {
        const cy = `${currentYear}`.substring(2, 4);
        const y = number > cy ? "20" + number : number + "00";
        if (y >= currentYear) return callback(`${y}-01-01`);
      }
    } else {
      const array = string.split("-");
      const lastNumber = vm.$number(vm.$arrayLastItem(array));
      if (array.length == 3 && lastNumber >= 22) {
        const resMoment = moment(date_df, "DDMMYY", "ru");
        const res = resMoment.isValid() ? resMoment.format("YYYY-MM-DD") : null;
        return callback(res);
      } else if (array.length == 2 && lastNumber >= 2022) {
        const resMoment = moment(date_df, "MMYYYY", "ru");
        const res = resMoment.isValid() ? resMoment.format("YYYY-MM-DD") : null;
        return callback(res);
      } else if (number.length == 6) {
        const d = number.substring(0, 2);
        const m = number.substring(2, 4);
        const y = number.substring(4, 6);
        if (`20${y}` >= currentYear && m <= 12 && d <= 31) {
          const resMoment = moment(date_df, "DDMMYY", "ru");
          let res = null;
          if (resMoment.isValid()) res = resMoment.format("YYYY-MM-DD");
          return callback(res);
        }
      }
    }
  } else if (monthRegex.test(string)) {
    const date = monthToNumber(string);
    if (date) return tryParseDate(date, callback);
  } else if (vm.$number(string.substring(0, 4)) > 2000) {
    const y = string.substring(0, 4)
    const m = string.substring(4).replace(/[-,.-/- ]/g, "")
    return tryParseDate(`${m}-${y}`, callback)
  }

  callback(null);
}

function monthToNumber(str) {
  let res = null;

  for (let index = 0; index < months.length; index++) {
    const month = months[index];
    const regex = new RegExp(`^${month}`, "i");
    if (regex.test(str)) {
      const array = str.split("-");

      res = array
        .map((item) => {
          if (regex.test(item)) return `${index + 1}`.padStart(2, "0");
          return item;
        })
        .filter((x) => vm.$number(x))
        .join("-");
      break;
    }
  }

  return res;
}
