<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import TableSort from "@/components/table-sort.vue";
import CrudAccessVue from "@/mixins/crud-access.vue";
import ModalsTemplate from "@/components/modals-template.vue";

export default {
  components: {
    ConfirmDialog,
    TableSort,
    ModalsTemplate,
  },

  props: {
    value: Object,
  },

  data: (vm) => ({
    dialog: true,

    options: {},
    page: 1,
    itemsPerPage: 50,

    isActivate: false,
    isDelete: false,

    isLoading: false,
    selected: {},

    sortParams: {
      sort_by: '"ProductName"',
      sort_direction: "desc",
    },

    ln: "price_list.show",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },

    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  mixins: [CrudAccessVue],

  computed: {
    ...mapGetters("price-list-show", ["GET", "GET_COUNT"]),
    size: (vm) => vm.$store.getters.GET_SIZE,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    headers: (vm) => [
      {
        text: "#",
        value: "number",
        width: 40,
        align: "center",
      },
      {
        text: vm.tn("table.productName"),
        value: "productName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.manufacturerName"),
        value: "manufacturerName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.linkedProductName"),
        value: "linkedProductName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.linkedProductManufacturerName"),
        value: "linkedProductManufacturerName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.priceType"),
        value: "priceType",
        _filter: (v) => vm.$sum(v),
      },
      {
        text: vm.tn("table.amount"),
        value: "amount",
        _filter: (v) => vm.$sum(v),
      },
      {
        text: vm.tn("table.shelfLife"),
        value: "shelfLife",
        _filter: (v) => vm.$sum(v),
      },
      {
        text: vm.tn("table.isPublished"),
        value: "isPublished",
        minWidth: 200,
      },
      {
        text: vm.tn("table.load"),
        value: "load",
        minWidth: 200,
      },
      {
        text: vm.tn("table.code"),
        value: "code",
        minWidth: 200,
      },
      { text: "", value: "actions", width: 10, align: "center" },
    ],

    sortItems: (vm) =>
      vm.headers
        .filter(
          (x) => x.value != "actions" && x.value != "number" && x._sort != false
        )
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),
  },

  methods: {
    ...mapActions("price-list-show", ["GET_API", "REMOVE_API"]),
    ...mapActions("price-list", ["ACTIVATE_API"]),

    async getEntries() {
      this.isLoading = true;
      const params = { options: { ...this.options } };
      params.options.sort_by = this.sortParams.sort_by;
      params.options.sort_direction = this.sortParams.sort_direction;
      params.filterParams = { priceListGuid: this.value.guid };

      await this.GET_API(params);
      this.isLoading = false;
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    async onDelete({ close, setLoading }) {
      setLoading(true);
      const status = await this.REMOVE_API(this.selected);
      setLoading(false);
      if (status) {
        close();
        this.getEntries();
      }
    },

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onClose() {
      this.dialog = false;
    },

    async onActivate({ close, setLoading }) {
      setLoading(true);
      const params = { priceListGuid: this.value.guid };
      const status = await this.ACTIVATE_API(params);
      setLoading(false);
      if (status) {
        close();
        this.dialog = false;
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("success_active"),
          color: "success",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template
    :width="1800"
    hideToolbar
    v-model="dialog"
  >
    <confirm-dialog
      :text="tn('delete_alert')"
      @close="isDelete = false"
      v-if="isDelete"
      @accept="onDelete"
    />

    <confirm-dialog
      :text="tn('activate_alert')"
      @close="isActivate = false"
      v-if="isActivate"
      @accept="onActivate"
    />

    <div class="d-flex align-items-center pb-4">
      <div>
        <h3>{{ value.provider }}</h3>
        <span> {{ value.documentDate | date }} </span>
      </div>

      <v-spacer />

      <v-btn
        @click="isActivate = true"
        outlined
        color="primary"
        :loading="isLoading"
        v-if="!value.isCurrent"
      >
        {{ tn("activate") }}
      </v-btn>

      <div class="px-2" />

      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          append-icon="mdi-magnify"
          @click:clear="onClear"
          clearable
        />
      </div>

      <div class="px-2" />

      <v-btn
        :title="tn('refresh')"
        @click="onClose"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>

    <v-data-table
      :height="size.height - 260"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
      class="fixed-right"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.id == item.id && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td
                v-else-if="head.value == 'actions'"
                :key="head.value"
                class="px-0"
              >
                <div class="d-flex tb-action-wrap">
                  <v-btn
                    :title="tn('table.actions.delete')"
                    v-if="isAccessDelete"
                    @click="isDelete = true"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>

              <td
                v-else-if="head._filter"
                :key="head.value"
              >
                <div
                  v-text="head._filter(item[head.value])"
                  class="text-nowrap"
                />
              </td>

              <td
                v-else
                :key="head.value"
              >
                <div
                  :style="{ minWidth: head.minWidth + 'px' }"
                  v-text="item[head.value]"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </modals-template>
</template>
