export default {
  text: {
    regex: "[a-zA-Z'` ]*$",
    showMaskOnHover: false,
  },

  date: {
    alias: "datetime",
    inputFormat: "dd.mm.yyyy",
    outputFormat: "yyyy-mm-dd",
    placeholder: "дд-мм-гггг",
    showMaskOnHover: false,
    autoUnmask: true,
  },

  datetime: {
    alias: "datetime",
    inputFormat: "dd.mm.yyyy HH:MM",
    outputFormat: "yyyy-mm-dd HH:MM",
    placeholder: "дд-мм-гггг чч:мм",
    showMaskOnHover: false,
    autoUnmask: true,
  },

  number: {
    alias: "numeric",
    groupSeparator: " ",
    autoUnmask: true,
    rightAlign: false,
    showMaskOnHover: false,
    min: 0,
    showMaskOnFocus: false
  },

  number_only: {
    alias: "numeric",
    autoUnmask: true,
    rightAlign: false,
    showMaskOnHover: false,
    min: 0,
    showMaskOnFocus: false
  },

  percent: {
    alias: "percentage",
    max: 100,
    showMaskOnHover: false,
    autoUnmask: true,
    rightAlign: false,
  },

  email: {
    mask: "*{1,20}[.*{1,20}][.*{1,20}][.*{1,20}]@*{1,20}[.*{2,6}][.*{1,2}]",
    greedy: false,
    onBeforePaste: (pastedValue) => {
      pastedValue = pastedValue.toLowerCase();
      return pastedValue.replace("mailto:", "");
    },
    definitions: {
      "*": {
        validator: "[0-9A-Za-z!#$%&'*+/=?^_`{|}~-]",
        cardinality: 1,
        casing: "lower",
      },
    },
  },

  phone: {
    mask: "+\\9\\98 99 999 99 99",
    autoUnmask: true,
    showMaskOnHover: true,
  },
};
