import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource = null;

export const state = () => ({
  //
});

export const mutations = {
  //
};

export const actions = {
  async SEARCH(ctx, { value, }) {
    cancelSource?.cancel();
    cancelSource = CancelToken.source();

    const search = `${value || ""}`;
    if (search.length < 2) return [];

    const request = { progress: false, cancelToken: cancelSource.token, };
    const res = await vm.$axios.$get("user/lookUp", { search, }, request);
    return vm.$array(res?.data?.items);
  },
};

export const getters = {
  //
};
