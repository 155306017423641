import access from "./access.js";

import store from "../store";
export default async (to, from, next) => {
  const isUser = await initUser();
  if (to.path == "/") {
    return isUser ? initialPage(next) : next(true);
  } else if (to?.meta?.auth) {
    if (isUser) return next(true);
  } else if (to?.meta?.claim) {
    let key = to.meta.accessKey || to.path;
    if (to.params.guid) key = key.replace(`/${to.params.guid}`, "");
    if (access("view", key)) return next(true);
  }

  next("/");
};

async function initUser() {
  let isUser = !!store.state.auth.user.exist;
  if (isUser) return true;
  store.dispatch("SET_INITING", true);
  await store.dispatch("auth/INIT_USER");
  isUser = !!store.state.auth.user.exist;
  if (isUser) await store.dispatch("INIT_STATES");
  store.dispatch("SET_INITING", false);
  return isUser;
}
function initialPage(next) {
  const claims = store.state.auth.claims.filter((x) => x.startsWith("view_"));
  if (claims.length) next(claims[0].replace("view_", ""));
  else next(true);
}
