var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.isPermissionOrder)?_c('confirm-dialog',{attrs:{"text":_vm.tn('permission_order_alert')},on:{"close":function($event){_vm.isPermissionOrder = false},"accept":_vm.onPermissionOrder}}):_vm._e(),(_vm.isFilter)?_c('price-list-viewer-filter',{attrs:{"value":_vm.filterParams,"statuses":_vm.GET_STATUSES},on:{"input":_vm.onFilter,"close":function($event){_vm.isFilter = false}}}):_vm._e(),(_vm.isShowType)?_c('price-list-viewer-type',{attrs:{"value":{
      guid: _vm.selected.guid,
      isStaff: _vm.selected.isStaff,
      isPharmacy: _vm.selected.isPharmacy,
      isProvider: _vm.selected.isProvider,
    }},on:{"success":_vm.getEntries,"close":function($event){_vm.isShowType = false}}}):_vm._e(),_c('div',{staticClass:"d-flex align-items-center pb-4"},[_c('h3',[_vm._v(_vm._s(_vm.tn("title")))]),_c('v-spacer'),_c('v-btn',{attrs:{"title":_vm.tn('refresh'),"loading":_vm.isLoading,"outlined":"","color":"primary","min-width":"38","width":"38"},on:{"click":_vm.getEntries}},[_c('v-icon',[_vm._v("mdi-sync")])],1),_c('div',{staticClass:"px-2"}),_c('table-sort',{attrs:{"sortItems":_vm.sortItems,"disabled":_vm.isLoading},on:{"refresh":_vm.onSort},model:{value:(_vm.sortParams),callback:function ($$v) {_vm.sortParams=$$v},expression:"sortParams"}}),_c('div',{staticClass:"px-2"}),_c('v-badge',{attrs:{"dot":"","overlap":"","color":"error","value":_vm.$filterItemsCount(_vm.filterParams)}},[_c('v-btn',{attrs:{"title":_vm.tn('filter_btn'),"outlined":"","color":"primary","min-width":"38","width":"38"},on:{"click":function($event){_vm.isFilter = true}}},[_c('v-icon',[_vm._v("mdi-filter")])],1)],1),_c('div',{staticClass:"px-2"}),_c('div',[_c('v-text-field',{staticClass:"w-250px",attrs:{"label":_vm.tn('search'),"outlined":"","dense":"","hide-details":"","append-icon":"mdi-magnify","clearable":""},on:{"click:append":_vm.getEntries,"click:clear":_vm.onClear},model:{value:(_vm.options.search),callback:function ($$v) {_vm.$set(_vm.options, "search", $$v)},expression:"options.search"}})],1)],1),(_vm.isInit)?_c('v-data-table',{staticClass:"fixed-right",attrs:{"height":_vm.size.height - 220,"headers":_vm.headers,"items":_vm.entries,"mobile-breakpoint":0,"loading":_vm.isLoading,"fixed-header":"","options":_vm.options,"server-items-length":_vm.entriesCount,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
      itemsPerPageOptions: [50, 100],
      showCurrentPage: true,
      showFirstLastPage: true,
    },"disable-sort":"","dense":""},on:{"update:options":function($event){_vm.options=$event},"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"body",fn:function(props){return [_c('tbody',_vm._l((props.items),function(item,key){return _c('tr',{key:key,staticClass:"cursor-pointer"},[_vm._l((props.headers),function(head){return [(head.value == 'number')?_c('td',{key:head.value,staticClass:"px-1 text-center"},[_c('span',{staticClass:"text-nowrap",domProps:{"textContent":_vm._s(_vm.$sum((_vm.page - 1) * _vm.itemsPerPage + (key + 1)))}})]):(head._filter)?_c('td',{key:head.value},[_c('div',{staticClass:"text-nowrap",domProps:{"textContent":_vm._s(head._filter(item[head.value]))}})]):(head.value == 'actions')?_c('td',{key:head.value,staticClass:"px-0"},[_c('div',{staticClass:"d-flex tb-action-wrap"},[_c('v-btn',{attrs:{"title":_vm.tn('table.actions.show_set_type'),"icon":""},on:{"click":function($event){return _vm.onShowSetType({ item })}}},[_c('v-icon',[_vm._v(" mdi-account-cog-outline ")])],1),_c('v-btn',{attrs:{"title":_vm.tn('table.actions.show_cart'),"icon":""},on:{"click":function($event){return _vm.onOpenBasket(item)}}},[_c('v-icon',[_vm._v("mdi-cart-variant")])],1),_c('v-btn',{attrs:{"title":_vm.tn('table.actions.can_use_order'),"icon":""},on:{"click":function($event){return _vm.onShowPermissionOrder({ item })}}},[_c('v-icon',[_vm._v("mdi-key-outline")])],1)],1)]):_c('td',{key:head.value},[_c('div',{style:({ minWidth: head.minWidth + 'px' }),domProps:{"textContent":_vm._s(item[head.value])}})])]})],2)}),0)]}}],null,false,2032288675)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }