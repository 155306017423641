<script>
import { debounce } from "debounce";

import AddPriceType from "../modals/references/add-price-type.vue";
import AddPriceListProvider from "../modals/crm/add-price-list-provider.vue";
import AddPriceListProviderUser from "@/components/modals/crm/add-price-list-provider-user.vue";

export default {
  props: {
    value: { type: Object },
    searchStore: { type: String, required: true },
    required: { type: Boolean, default: () => false },
    autofocus: { type: Boolean, default: () => false },
    disableAdd: { type: Boolean, default: () => false },
    clearable: { type: Boolean, default: () => false },
    dense: { type: Boolean, default: () => true },
    hideDetails: { type: Boolean, default: () => true },
    label: { type: String, default: () => null },
    disabled: { type: Boolean, default: () => false },
    query: { type: Object, default: () => null },
    itemText: { type: String, default: () => "name" },
    isId: { type: Boolean },
  },

  components: {
    AddPriceType,
  },

  data: () => ({
    entries: [],
    searchValue: null,
    select: null,
    isLoading: false,
    isAdd: false,

    addDialogs: {
      "price-type": AddPriceType,
      "price-list-provider": AddPriceListProvider,
      "price-list-provider-user": AddPriceListProviderUser,
    },

    isAccessAdd: false,
  }),

  watch: {
    searchValue(name) {
      if (!name) return;
      if (name != this.select?.name) this.onSearch(name);
    },

    value(v) {
      this.select = v;
    },

    select(v) {
      this.$emit("input", v);
    },
  },

  created() {
    this.isAccessAdd = this.$access("add", `/${this.searchStore}s`);

    if (this.value) {
      this.select = this.value;
      this.entries.push(this.value);
    }
  },

  methods: {
    onSearch: debounce(async function (value) {
      this.isLoading = true;
      this.entries = await this.getSearchStore({ value, query: this.query });
      this.isLoading = false;
    }, 200),

    getSearchStore(v) {
      return this.$store.dispatch(`${this.searchStore}/SEARCH`, v);
    },

    onSave(data) {
      if (data) {
        this.select = data;
        this.entries.push(data);
      }
    },

    handleEnter(e) {
      if (this.isAccessAdd && !this.disableAdd && e.ctrlKey) this.isAdd = true;
      else this.$emit("enter");
    },
  },
};
</script>

<template>
  <div class="d-flex" style="flex: 1">
    <component
      :is="addDialogs[searchStore]"
      v-if="isAdd"
      @close="isAdd = false"
      @saved="onSave"
      :query="query"
    />

    <v-autocomplete
      :autofocus="autofocus"
      :search-input.sync="searchValue"
      :items="entries"
      :loading="isLoading"
      :item-text="itemText"
      :item-value="isId ? 'id' : 'guid'"
      return-object
      outlined
      v-model="select"
      :label="label"
      @keyup.enter="handleEnter"
      :required="required"
      :rules="required ? [$rules.required] : []"
      :hide-details="hideDetails"
      :dense="dense"
      :clearable="clearable"
      :disabled="disabled"
      @change="$emit('change')"
      no-filter
    >
      <template
        v-if="addDialogs[searchStore] && isAccessAdd && !disableAdd"
        v-slot:append
      >
        <div class="btn-wrap">
          <v-btn @click="isAdd = true" absolute icon tabindex="-1">
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </div>
      </template>
    </v-autocomplete>
  </div>
</template>

<style lang="scss" scoped>
.btn-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 22px;
  width: 22px;
}
</style>
