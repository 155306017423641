<script>
import { mapActions, mapGetters } from "vuex";
import ModalsTemplate from "../../modals-template.vue";
import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import BindPriceListProviderUser from "@/components/modals/crm/bind-price-list-provider-user.vue";

export default {
  components: { ModalsTemplate, ConfirmDialog, BindPriceListProviderUser },
  props: {
    value: {
      type: Object,
    },
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,
    isInited: false,

    isDelete: false,
    isAdd: false,

    options: {},
    page: 1,
    itemsPerPage: 50,

    selected: {},

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    ln: "price_list_provider.bind",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },

    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    ...mapGetters("price-list-provider-user", ["GET", "GET_COUNT"]),

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    size: (vm) => vm.$store.getters.GET_SIZE,

    headers: (vm) => [
      {
        text: "#",
        value: "number",
        width: 40,
        align: "center",
      },
      {
        text: vm.tn("table.name"),
        value: "firstName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.last_name"),
        value: "lastName",
        minWidth: 200,
      },

      { text: "", value: "actions", width: 10, align: "center" },
    ],
  },

  async created() {
    const q = this.$route.query;
    const sq = this.$browserStore.getSession("tb" + this.$route.path) || {};
    const page = q.page || sq.page;
    const per_page = q.per_page || sq.per_page;
    this.page = this.$number(page) || 1;
    this.itemsPerPage = this.$number(per_page) || 50;

    this.isInited = true;
  },

  methods: {
    ...mapActions("price-list-provider-user", ["GET_API", "UN_BIND_API"]),

    async getEntries() {
      this.isLoading = true;
      const filterParams = {};
      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;
      filterParams.providerGuid = this.value.guid;

      const options = { options: { ...this.options }, filterParams };

      await this.GET_API(options);
      this.isLoading = false;
      this.setRoute();
    },

    setRoute() {
      const query = { page: this.options.page, per_page: this.itemsPerPage };
      const page = this.$number(this.$route.query.page);
      const per_page = this.$number(this.$route.query.per_page);
      if (query.page != page || query.per_page != per_page) {
        this.$router.replace({ query });
        this.$browserStore.setSession("tb" + this.$route.path, query);
      }
    },

    onShowDelete({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isDelete = true));
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);
      const params = {
        providerGuid: this.value.guid,
        providerUserGuid: this.selected.guid,
      };
      await this.UN_BIND_API(params);
      this.getEntries();
      setLoading(false);
      close();
    },

    onAdd() {
      this.selected = {};
      this.$nextTick(() => (this.isAdd = true));
    },
  },
};
</script>

<template>
  <modals-template
    v-if="isInited"
    :width="600"
    :title="tn('title')"
    v-model="dialog"
  >
    <BindPriceListProviderUser
      :editData="value"
      @saved="getEntries"
      v-if="isAdd"
      @close="isAdd = false"
    />

    <confirm-dialog
      :text="tn('delete_alert')"
      @close="isDelete = false"
      v-if="isDelete"
      @accept="onDelete"
    />

    <div class="d-flex align-center pb-3">
      <v-spacer />

      <v-btn
        min-width="38"
        width="38"
        :title="tn('add_btn')"
        @click="onAdd"
        color="primary"
        outlined
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <v-data-table
      :height="size.height - 500"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
      class="fixed-right"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span class="text-nowrap" v-text="key + 1" />
              </td>

              <td
                v-else-if="head.value == 'actions'"
                :key="head.value + 1"
                class="px-0"
              >
                <div class="d-flex tb-action-wrap">
                  <v-btn
                    :title="tn('table.actions.delete')"
                    @click="onShowDelete({ item })"
                    icon
                  >
                    <v-icon> mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>

              <td v-else-if="head._filter" :key="head.value + 2">
                <div
                  v-if="head._html"
                  v-html="head._filter(item[head.value])"
                />
                <div
                  v-else
                  v-text="head._filter(item[head.value])"
                  class="text-nowrap"
                />
              </td>

              <td v-else :key="head.value + 3">
                <div
                  :style="{ minWidth: head.minWidth + 'px' }"
                  v-text="item[head.value]"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>

    <div class="d-flex justify-center">
      <v-btn
        width="50%"
        :loading="isLoading"
        color="primary"
        @click="$emit('close')"
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("close_btn") }}
      </v-btn>
    </div>
  </modals-template>
</template>
