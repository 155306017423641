<script>
import { mapActions, mapGetters } from "vuex";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import Card from "@/components/modals/info/card.vue";
import AddHomePageTile from "@/components/modals/info/add-home-page-tile.vue";

export default {
  components: { ConfirmDialog, Card, AddHomePageTile },

  data: (vm) => ({
    isAdd: false,
    isDelete: false,

    selected: {},

    ln: "home_page_tile",
    tn: (n) => vm.$t(vm.ln + "." + n),

    loaders: {},

    page: 1,
    itemsPerPage: 20,

    searchText: null,
  }),

  computed: {
    ...mapGetters("home-page-tile", ["GET", "GET_COUNT"]),
    size: (vm) => vm.$store.getters.GET_SIZE,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    pagerLength: (vm) => Math.ceil(vm.entriesCount / vm.itemsPerPage),
  },

  created() {
    this.getEntries();
  },

  watch: {
    page() {
      this.getEntries();
    },
  },

  methods: {
    ...mapActions("home-page-tile", ["GET_API", "REMOVE_API"]),

    async getEntries() {
      this.$store.dispatch("SET_LOADING", true);

      const params = {
        options: {
          page: this.page,
          itemsPerPage: this.itemsPerPage,
        },
      };

      await this.GET_API(params);

      this.$store.dispatch("SET_LOADING", false);
    },

    async onDelete({ close, setLoading }) {
      setLoading(true);
      await this.REMOVE_API(this.selected.guid);
      this.getEntries();
      setLoading(false);
      close();
    },

    onShowDelete({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isDelete = true));
    },

    onEdit({ item }) {
      this.selected = { ...item };
      this.$nextTick(() => (this.isAdd = true));
    },

    onAdd() {
      this.selected = {};
      this.$nextTick(() => (this.isAdd = true));
    },
  },
};
</script>

<template>
  <v-container style="max-width: 992px">
    <ConfirmDialog
      v-if="isDelete"
      :text="tn('delete_alert')"
      @close="isDelete = false"
      @accept="onDelete"
    />

    <add-home-page-tile
      v-if="isAdd"
      @saved="getEntries"
      :editData="selected"
      @close="isAdd = false"
    />

    <div class="d-flex">
      <div v-text="tn('title')" class="title" />
      <v-spacer />

      <v-btn
        min-width="38"
        width="38"
        :title="tn('refresh_btn')"
        @click="getEntries"
        color="primary"
        outlined
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <v-btn
        min-width="38"
        width="38"
        :title="tn('add_btn')"
        @click="onAdd"
        color="primary"
        outlined
        class="ml-3"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </div>

    <div class="py-3" />
    <v-row>
      <v-col v-for="item in entries" :key="item.guid" cols="12" md="6">
        <Card
          :background="item.color"
          :icon="item.icon"
          :count="$sum(item.count)"
          :nameRu="item.nameRu"
          :nameUz="item.nameUz"
          :nameUzLat="item.nameUzLat"
          :url="item.url"
          @edit="onEdit({ item })"
          @delete="onShowDelete({ item })"
        />
      </v-col>
    </v-row>

    <v-pagination
      v-if="entriesCount > itemsPerPage"
      class="mt-4"
      v-model="page"
      :length="pagerLength"
    />
  </v-container>
</template>
