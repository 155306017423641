<script>
export default {
  props: { overlay: Boolean },
};
</script>

<template>
  <v-dialog :value="overlay" persistent width="300" style="z-index: 20001">
    <v-card class="pa-5">
      Загрузка...
      <v-progress-linear height="5" indeterminate class="mt-1" />
    </v-card>
  </v-dialog>
</template>
