<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data: () => ({
    navbar: true,
  }),

  computed: {
    ...mapGetters("auth", ["GET_USER"]),

    menuItems: (vm) => [
      {
        name: vm.$t("home.price_list"),
        url: "/price-list",
        claim: true,
      },
      {
        name: vm.$t("home.price_list_provider"),
        url: "/price-list-provider",
        claim: true,
      },
      {
        name: vm.$t("home.price_list_provider_user"),
        url: "/price-list-provider-user",
        claim: true,
      },
      {
        name: vm.$t("home.price_list_viewer"),
        url: "/price-list-viewer",
        claim: true,
      },
      {
        name: vm.$t("home.baskets"),
        url: "/baskets",
        claim: true,
      },
      {
        name: vm.$t("home.home_page_tile"),
        url: "/home-page-tile",
        claim: true,
      },
      {
        name: vm.$t("home.reference"),
        children: [
          {
            name: vm.$t("home.price_type"),
            url: "/price-type",
            claim: true,
          },
          {
            name: vm.$t("home.product"),
            url: "/product",
            claim: true,
          },
          {
            name: vm.$t("home.country"),
            url: "/country",
            claim: true,
          },
          {
            name: vm.$t("home.region"),
            url: "/region",
            claim: true,
          },
          {
            name: vm.$t("home.brand"),
            url: "/brand",
            claim: true,
          },
          {
            name: vm.$t("home.international_name"),
            url: "/international-name",
            claim: true,
          },
          {
            name: vm.$t("home.manufacturer"),
            url: "/manufacturer",
            claim: true,
          },
        ],
      },
    ],

    myMenuItems() {
      const menuItems = [...this.menuItems];

      return menuItems
        .map((menu) => {
          if (menu.claim) return this.checkMenu(menu);
          else if (menu.access) return menu;
          else if (menu?.children) {
            const children = menu.children.filter((child) => {
              if (child.access) return true;
              else return this.checkMenu(child);
            });

            if (!children.length) return null;
            else {
              menu.children = children;
              return menu;
            }
          }
        })
        .filter((x) => x);
    },
  },

  methods: {
    ...mapActions("auth", ["LOGOUT"]),

    checkMenu(menu) {
      return this.$access("view", menu.url) ? menu : null;
    },
  },
};
</script>

<template>
  <div>
    <v-app-bar height="70" app class="bg-secondary" elevation="1">
      <v-app-bar-nav-icon @click="navbar = !navbar"></v-app-bar-nav-icon>
      <h3 class="pl-2">{{ $t("home.app_title") }}</h3>
      <v-spacer />
      <v-btn
        :title="$serverUrl"
        color="red"
        dark
        elevation="0"
        v-if="$serverUrl.includes('test')"
        :href="$serverUrl + 'swagger'"
        target="_blank"
      >
        TEST SERVER
      </v-btn>
    </v-app-bar>

    <v-navigation-drawer
      class="my-nav bg-secondary"
      width="300"
      v-model="navbar"
      app
      dark
    >
      <v-list-item style="height: 70px">
        <v-list-item-content>
          <v-list-item-title class="text-h6 py-2">
            {{ GET_USER.fullName }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <small>версия: {{ $version }}</small>
          </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action>
          <transition-group name="circle">
            <v-btn
              title="Светлая тема"
              v-if="$vuetify.theme.dark"
              key="dark"
              icon
              @click="$vuetify.theme.dark = !$vuetify.theme.dark"
              absolute
              top
            >
              <v-icon class="mdi-rotate-225"> mdi-weather-sunny </v-icon>
            </v-btn>
            <v-btn
              title="Темная тема"
              absolute
              top
              v-else
              key="light"
              icon
              @click="$vuetify.theme.dark = !$vuetify.theme.dark"
            >
              <v-icon>
                {{ "mdi-weather-night" }}
              </v-icon>
            </v-btn>
          </transition-group>
        </v-list-item-action>
      </v-list-item>
      <v-divider class="mb-2" />

      <v-list dense nav>
        <template v-for="(menuItem, index) in myMenuItems">
          <template v-if="menuItem.children">
            <v-list-group
              active-class="white--text"
              :key="'n' + index"
              :value="menuItem.active"
            >
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title v-text="menuItem.name" />
                </v-list-item-content>
              </template>

              <v-list-item
                active-class="white--text"
                :to="child.url"
                v-for="child in menuItem.children"
                :key="child.url"
              >
                <v-list-item-content class="pl-3">
                  <v-list-item-title v-text="child.name" />
                </v-list-item-content>
              </v-list-item>
              <v-divider :key="'d' + index" />
            </v-list-group>
          </template>

          <v-list-item
            active-class="white--text"
            v-else
            :to="menuItem.url"
            :key="menuItem.url"
          >
            <v-list-item-content>
              <v-list-item-title v-text="menuItem.name" />
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>

      <template v-slot:img>
        <v-img
          gradient="to right top, #1f283ea6, #1f283eeb"
          height="100%"
          src="/img/sidebar.jpg"
        />
      </template>

      <template v-slot:append>
        <v-divider />
        <v-list dense nav>
          <v-list-item to="/change-password">
            <v-list-item-content>
              <v-list-item-title> Изменить пароль </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-list dense nav>
          <v-list-item class="red--text" @click="LOGOUT">
            <v-list-item-content>
              <v-list-item-title> Выйти из системы </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </template>
    </v-navigation-drawer>

    <v-main>
      <div class="px-5 pt-5">
        <router-view />
      </div>
    </v-main>
  </div>
</template>

<style lang="scss" scoped>
.title-wrap {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: 100%;
}

.circle-enter-active {
  transition: all 1s;
  transform: rotate(0);
}

.circle-enter {
  opacity: 0;
  transform: rotate(250deg);
}

.circle-leave-active {
  opacity: 0;
  transform: rotate(-150deg);
}
</style>
