<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import ConfirmDialog from "@/components/modals/confirm-dialog.vue";
import AddPriceType from "@/components/modals/references/add-price-type.vue";
import TableSort from "@/components/table-sort.vue";
import CrudAccessVue from "@/mixins/crud-access.vue";

export default {
  components: { ConfirmDialog, AddPriceType, TableSort },
  data: (vm) => ({
    options: {},
    page: 1,
    itemsPerPage: 50,

    isAdd: false,
    isDelete: false,

    isLoading: false,
    selected: {},

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    ln: "price_type",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  mixins: [CrudAccessVue],

  computed: {
    ...mapGetters("price-type", ["GET", "GET_COUNT"]),
    size: (vm) => vm.$store.getters.GET_SIZE,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    headers: (vm) => [
      { text: "#", value: "number", width: 40, align: "center" },
      { text: vm.tn("table.name"), value: "name" },
      {
        text: vm.tn("table.order_number"),
        value: "orderNumber",
      },
      ...vm.$baseTableColumns(vm),
      { text: "", value: "actions", width: 10, align: "center" },
    ],

    sortItems: (vm) =>
      vm.headers
        .filter(
          (x) => x.value != "actions" && x.value != "number" && x._sort != false
        )
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),
  },

  methods: {
    ...mapActions("price-type", ["GET_API", "REMOVE_API"]),

    async getEntries() {
      this.isLoading = true;
      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;
      const options = { options: { ...this.options } };
      await this.GET_API(options);
      this.isLoading = false;
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    async onDelete({ close, setLoading }) {
      setLoading(true);
      await this.REMOVE_API(this.selected.guid);
      this.getEntries();
      setLoading(false);
      close();
    },

    onEdit({ item }) {
      this.selected = item ? { ...item } : {};
      this.$nextTick(() => (this.isAdd = true));
    },

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },
  },
};
</script>

<template>
  <div>
    <add-price-type
      :editData="selected"
      @saved="getEntries"
      v-if="isAdd"
      @close="isAdd = false"
    />

    <confirm-dialog
      :text="tn('delete_alert')"
      @close="isDelete = false"
      v-if="isDelete"
      @accept="onDelete"
    />

    <div class="d-flex align-items-center pb-4">
      <h3>{{ tn("title") }}</h3>

      <v-spacer />

      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <v-btn
        min-width="38"
        width="38"
        :title="tn('add_btn')"
        @click="onEdit"
        color="primary"
        outlined
        v-if="isAccessAdd"
        class="mr-4"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          append-icon="mdi-magnify"
          @click:clear="onClear"
          clearable
        />
      </div>
    </div>

    <v-data-table
      :height="size.height - 220"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
      class="fixed-right"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td
                v-else-if="head.value == 'actions'"
                :key="head.value"
                class="px-0"
              >
                <div class="d-flex tb-action-wrap">
                  <v-btn
                    :title="tn('table.actions.edit')"
                    v-if="isAccessEdit"
                    @click="onEdit({ item })"
                    icon
                  >
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <div v-else style="width: 36px" />
                  <v-btn
                    :title="tn('table.actions.delete')"
                    v-if="isAccessDelete"
                    @click="isDelete = true"
                    icon
                  >
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </div>
              </td>

              <td v-else-if="head._filter" :key="head.value">
                <div v-text="head._filter(item[head.value])" />
              </td>

              <td v-else :key="head.value">
                <div v-text="item[head.value]" />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
