import Vue from "vue";

export const toIso = (date, withoutTime) => {
  if (!isValidDate(date)) return null;
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  let dd = `${year}-${month}-${day}`

  if (!withoutTime) {
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    dd += `T${hours}:${minutes}`;
  } else if (dd.length != 10) return null

  return dd
}

export const date = (stringdate) => {
  if (!stringdate) return null;
  const date = new Date(stringdate);
  if (!isValidDate(date)) return null;
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  return `${day}.${month}.${year}`;
};

export const getCurrentDate = (dd, mm, yy) => {
  const date = new Date();
  if (dd) date.setDate(date.getDate() + dd);
  if (mm) date.setMonth(date.getMonth() + mm);
  if (yy) date.setFullYear(date.getFullYear() + yy);

  return toIso(date);
};

export const time = (stringdate) => {
  if (!stringdate) return null;
  const date = new Date(stringdate);
  if (!isValidDate(date)) return "";
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
};

export const dateTime = (stringdate) => {
  if (!stringdate) return null;
  const date = new Date(stringdate);
  if (!isValidDate(date)) return null;
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day}.${month}.${year} ${hours}:${minutes}`;
};

export const dateTimeFull = (stringdate) => {
  if (!stringdate) return null;
  const date = new Date(stringdate);
  if (!isValidDate(date)) return null;
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const day = date.getDate().toString().padStart(2, "0");
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");
  return `${day}.${month}.${year} ${hours}:${minutes}:${seconds}`;
};

Vue.prototype.$toIso = toIso;
Vue.prototype.$getCurrentDate = getCurrentDate;
Vue.prototype.$date = date;
Vue.filter("date", date);
Vue.prototype.$time = time;
Vue.filter("time", time);
Vue.prototype.$dateTime = dateTime;
Vue.filter("dateTime", dateTime);
Vue.prototype.$dateTimeFull = dateTimeFull;
Vue.filter("dateTimeFull", dateTimeFull);

function isValidDate(d) {
  if (d instanceof Date && !isNaN(d)) {
    const year = d.getFullYear();
    return year > 1;
  } else return false;
}
