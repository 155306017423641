<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import TableSort from "@/components/table-sort.vue";
import CrudAccessVue from "@/mixins/crud-access.vue";
import AddPriceListProviderUser from "@/components/modals/crm/add-price-list-provider-user.vue";
import PriceListProviderUserFilter from "@/components/modals/crm/price-list-provider-user-filter.vue";

export default {
  components: {
    TableSort,
    AddPriceListProviderUser,
    PriceListProviderUserFilter,
  },

  data: (vm) => ({
    options: {},
    page: 1,
    itemsPerPage: 50,

    isFilter: false,

    isAdd: false,

    isLoading: false,
    selected: {},

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    filterParams: {},

    isInited: false,

    ln: "price_list_provider_user",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  mixins: [CrudAccessVue],

  computed: {
    ...mapGetters("price-list-provider-user", [
      "GET",
      "GET_COUNT",
      "GET_STATUSES",
    ]),
    size: (vm) => vm.$store.getters.GET_SIZE,

    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    headers: (vm) => [
      {
        text: "#",
        value: "number",
        width: 40,
        align: "center",
      },
      {
        text: vm.tn("table.name"),
        value: "firstName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.last_name"),
        value: "lastName",
        minWidth: 200,
      },
      {
        text: vm.tn("table.phone"),
        value: "phone",
        _html: true,
        _filter: (v) => {
          return `<span class="text-nowrap">${vm.$tel(v)}<br/></span>`;
        },
      },
      {
        text: vm.tn("table.contact_phone"),
        value: "contactPhone",
        _html: true,
        _filter: (v) => {
          return `<span class="text-nowrap">${vm.$tel(v)}<br/></span>`;
        },
      },
      {
        text: vm.tn("table.status"),
        value: "status",
        _filter: (id) => vm.GET_STATUSES.find((x) => x.id == id)?.name,
      },
      {
        text: vm.tn("table.last_login_date_time"),
        value: "lastLoginDateTime",
        _filter: (id) => vm.$dateTime(id),
        width: 130,
      },
      {
        text: vm.tn("table.valid_date_from"),
        value: "validDateFrom",
        _filter: (id) => vm.$dateTime(id),
        width: 130,
      },
      {
        text: vm.tn("table.valid_date_till"),
        value: "validDateTill",
        _filter: (id) => vm.$dateTime(id),
        width: 130,
      },

      ...vm.$baseTableColumns(vm),

      { text: "", value: "actions", width: 10, align: "center" },
    ],

    sortItems: (vm) =>
      vm.headers
        .filter(
          (x) => x.value != "actions" && x.value != "number" && x._sort != false
        )
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),
  },

  created() {
    const q = this.$route.query;
    const sq = this.$browserStore.getSession("tb" + this.$route.path) || {};
    const page = q.page || sq.page;
    const per_page = q.per_page || sq.per_page;
    this.page = this.$number(page) || 1;
    this.itemsPerPage = this.$number(per_page) || 50;

    this.init();
  },

  methods: {
    ...mapActions("price-list-provider-user", [
      "GET_API",
      "INIT_STATUSES",
      "REMOVE_API",
    ]),

    async init() {
      this.$store.dispatch("SET_LOADING", true);
      await this.INIT_STATUSES();
      this.$store.dispatch("SET_LOADING", false);
      this.isInited = true;
    },

    async getEntries() {
      this.isLoading = true;

      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;

      const filterParams = this.$filterParamsMap(this.filterParams);
      const options = { options: { ...this.options }, filterParams };

      await this.GET_API(options);
      this.isLoading = false;
      this.setRoute();
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    setRoute() {
      const query = { page: this.options.page, per_page: this.itemsPerPage };
      const page = this.$number(this.$route.query.page);
      const per_page = this.$number(this.$route.query.per_page);
      if (query.page != page || query.per_page != per_page) {
        this.$router.replace({ query });
        this.$browserStore.setSession("tb" + this.$route.path, query);
      }
    },

    onEdit({ item }) {
      this.selected = item ? { ...item } : {};
      this.$nextTick(() => (this.isAdd = true));
    },

    onFilter(v) {
      this.filterParams = v;
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },
  },
};
</script>

<template>
  <div>
    <PriceListProviderUserFilter
      :value="filterParams"
      @input="onFilter"
      v-if="isFilter"
      :statuses="GET_STATUSES"
      @close="isFilter = false"
    />

    <AddPriceListProviderUser
      :editData="selected"
      @saved="getEntries"
      v-if="isAdd"
      @close="isAdd = false"
    />

    <div class="d-flex align-items-center pb-4">
      <h3>{{ tn("title") }}</h3>

      <v-spacer />

      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <v-btn
        min-width="38"
        width="38"
        :title="tn('add_btn')"
        @click="onEdit"
        color="primary"
        outlined
        v-if="isAccessAdd"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>

      <div class="px-2" />

      <v-badge
        dot
        overlap
        color="error"
        :value="$filterItemsCount(filterParams)"
      >
        <v-btn
          :title="tn('filter_btn')"
          @click="isFilter = true"
          outlined
          color="primary"
          min-width="38"
          width="38"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-badge>

      <div class="px-2" />

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          append-icon="mdi-magnify"
          @click:clear="onClear"
          clearable
        />
      </div>
    </div>

    <v-data-table
      v-if="isInited"
      :height="size.height - 220"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
      class="fixed-right"
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td
                v-else-if="head.value == 'actions'"
                :key="head.value + 1"
                class="px-0"
              >
                <div class="d-flex tb-action-wrap">
                  <v-btn
                    :title="tn('table.actions.edit')"
                    v-if="isAccessEdit"
                    @click="onEdit({ item })"
                    icon
                  >
                    <v-icon> mdi-pencil </v-icon>
                  </v-btn>
                </div>
              </td>

              <td v-else-if="head._filter" :key="head.value + 2">
                <div
                  v-if="head._html"
                  v-html="head._filter(item[head.value])"
                />
                <div
                  v-else
                  v-text="head._filter(item[head.value])"
                  class="text-nowrap"
                />
              </td>

              <td v-else :key="head.value + 3">
                <div
                  :style="{ minWidth: head.minWidth + 'px' }"
                  v-text="item[head.value]"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
