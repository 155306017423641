<script>
import { mapActions, mapGetters } from "vuex";

const ln = "price_list.items.edit";

export default {
  data: () => ({
    isLoading: false,
  }),

  computed: {
    ...mapGetters("price-list-items-delete", {
      priceList: "GET_PRICE_LIST",
      columns: "GET_COLUMNS",
    }),

    lineItems: (vm) => vm.priceList.lineItems,

    headers: (vm) => {
      const headers = [
        {
          text: vm.tn("name"),
          value: "productName",
        },
        {
          text: vm.tn("manufacturer"),
          value: "manufacturerName",
        },
        {
          text: vm.tn("expiry_date"),
          value: "shelfLife",
        },

        ...vm.columns.map((x) => {
          return {
            text: x.name,
            value: `price_${x.guid}`,
          };
        }),
      ];

      return headers;
    },

    size: (vm) => vm.$store.getters.GET_SIZE,
  },

  created() {
    if (this.lineItems?.length) {
      //
    } else {
      this.onExit();
    }
  },

  methods: {
    ...mapActions("price-list-items-delete", ["SAVE_PRICE_LIST"]),

    onExit() {
      this.$router.replace("/price-list");
    },

    tn(n) {
      return this.$t(ln + "." + n);
    },

    async onSendExcel() {
      this.$store.dispatch("SET_LOADING", true);
      this.isLoading = true;
      const status = await this.SAVE_PRICE_LIST();
      if (status) {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.tn("saved_alert"),
          color: "success",
        });

        this.onExit();
      }
      this.isLoading = false;
      this.$store.dispatch("SET_LOADING", false);
    },
  },
};
</script>

<template>
  <div>
    <div class="d-flex align-items-center pb-4">
      <div class="title">
        {{ priceList._providerName }}
      </div>

      <v-spacer />

      <div class="px-2" />

      <v-btn
        color="primary"
        class="transform-none"
        dark
        @click="onSendExcel"
        elevation="0"
        outlined
        :loading="isLoading"
        :title="tn('delete_and_save_text')"
      >
        {{ tn("delete_and_save_btn") }}
      </v-btn>

      <div class="px-2" />

      <v-btn @click="onExit" outlined color="red" width="36" min-width="36">
        <v-icon> mdi-close </v-icon>
      </v-btn>
    </div>

    <v-card class="card-wrapper" elevation="0">
      <v-data-table
        disable-sort
        :headers="headers"
        :items="lineItems"
        :height="size.height - 220"
        :items-per-page="50"
        :footer-props="{ itemsPerPageOptions: [50, 50] }"
        mobile-breakpoint="0"
        fixed-header
        calculate-widths
        dense
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              :style="{
                backgroundColor: item.invalid ? '#ff4d4d45!important' : '',
              }"
              v-for="head in headers"
              :key="head.value"
              v-text="item[head.value]"
            />
          </tr>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>
