<script>
import { mapActions, mapGetters } from "vuex";

import ModalsTemplate from "../../modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    editData: {
      type: Object,
    },
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,

    formData: {
      firstName: null,
      lastName: null,
      contactPhone: null,
      phone: null,
    },

    ln: "price_list_provider_user.add",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  computed: {
    ...mapGetters("price-list-provider-user", ["GET_STATUSES"]),
    statuses: (vm) => vm.GET_STATUSES,
  },

  created() {
    if (this.editData && this.editData.guid) {
      const formData = { ...this.editData };
      if (this.editData.contactPhone)
        formData.contactPhone = this.editData.contactPhone
          .replace("998", "")
          .trim();

      if (this.editData.phone)
        formData.phone = this.editData.phone.replace("998", "").trim();

      this.formData = formData;
    }
  },

  methods: {
    ...mapActions("price-list-provider-user", ["ADD_API"]),

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const formData = { ...this.formData };
        formData.contactPhone = `998${this.formData.contactPhone}`;
        formData.phone = `998${this.formData.phone}`;
        const data = await this.ADD_API(formData);
        this.isLoading = false;
        if (data) {
          this.$emit("saved", data);
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },
  },
};
</script>

<template>
  <modals-template :width="500" :title="tn('title')" v-model="dialog">
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-row>
        <v-col cols="12">
          <div>
            {{ tn("form.name") }}
          </div>
          <v-text-field
            autofocus
            outlined
            v-model="formData.firstName"
            @keyup.enter="$refs.lastName.focus()"
            dense
            required
            clearable
            :rules="[$rules.required]"
            hide-details
          />
        </v-col>

        <v-col cols="12">
          <div>
            {{ tn("form.last_name") }}
          </div>
          <v-text-field
            ref="lastName"
            outlined
            v-model="formData.lastName"
            @keyup.enter="$refs.contactPhone.focus()"
            dense
            required
            clearable
            :rules="[$rules.required]"
            hide-details
          />
        </v-col>

        <v-col cols="12">
          <div>
            {{ tn("form.contact_phone") }}
          </div>
          <v-text-field
            ref="contactPhone"
            outlined
            v-model="formData.contactPhone"
            @keyup.enter="$refs.phone.focus()"
            dense
            hide-details
            clearable
            v-inputmask="$masks.phone"
          />
        </v-col>

        <v-col cols="12">
          <div>
            {{ tn("form.phone") }}
          </div>
          <v-text-field
            ref="phone"
            outlined
            v-model="formData.phone"
            @keyup.enter="onSave"
            dense
            hide-details
            clearable
            required
            :rules="[$rules.phone]"
            v-inputmask="$masks.phone"
          />
        </v-col>

        <template v-if="editData?.guid">
          <v-col cols="12">
            <div>
              {{ tn("form.status") }}
            </div>
            <v-select
              ref="status"
              item-text="name"
              item-value="id"
              :items="statuses"
              outlined
              v-model="formData.status"
              dense
              hide-details
              clearable
            />
          </v-col>
        </template>
      </v-row>

      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
