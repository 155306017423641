<script>
import ModalsTemplate from "../../modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    value: {
      type: Object,
    },

    statuses: {
      type: Array,
      default: () => [],
    },
  },

  data: (vm) => ({
    dialog: true,

    formData: {
      country: null,
    },

    ln: "price_list_viewer.filter",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  created() {
    this.formData = { ...this.value };
  },

  methods: {
    async onApply() {
      this.$emit("input", this.formData);
      this.dialog = false;
    },

    async onReset() {
      this.formData = {};
      this.$nextTick(() => this.onApply());
    },
  },
};
</script>

<template>
  <modals-template :width="450" :title="tn('title')" v-model="dialog">
    <v-select
      :label="tn('form.status')"
      item-text="name"
      item-value="id"
      :items="statuses"
      outlined
      v-model="formData.status"
      hide-details
      dense
      clearable
    />

    <v-checkbox
      hide-details
      :label="tn('form.is_staff')"
      v-model="formData.isStaff"
    />
    <v-checkbox
      class="mt-1"
      hide-details
      :label="tn('form.is_pharmacy')"
      v-model="formData.isPharmacy"
    />
    <v-checkbox
      class="mt-1"
      hide-details
      :label="tn('form.is_provider')"
      v-model="formData.isProvider"
    />
    <v-checkbox
      class="mt-1"
      :label="tn('form.can_use_order')"
      v-model="formData.canUseOrder"
    />

    <div class="d-flex justify-space-between pt-5">
      <v-btn width="48%" color="error" @click="onReset" elevation="0">
        {{ tn("reset") }}
      </v-btn>
      <v-btn width="48%" color="primary" @click="onApply" elevation="0">
        {{ tn("apply") }}
      </v-btn>
    </div>
  </modals-template>
</template>
