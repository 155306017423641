import Vue from "vue";
const vm = Vue.prototype;

import axios from "axios";
const CancelToken = axios.CancelToken;
let cancelSource2 = null;

const url = "PriceListViewer";

export const state = () => ({
  itemsData: {
    items: [],
    total: 0,
  },
  statuses: [],
});

export const mutations = {
  SET_ITEMS(state, payload) {
    state.itemsData = {
      items: vm.$array(payload?.items),
      total: vm.$number(payload?.totalCount),
    };
  },
  SET_STATUSES(state, payload) {
    state.statuses = vm.$array(payload);
  },
};

export const actions = {
  async GET_API({ commit }, { options, filterParams = {} }) {
    cancelSource2?.cancel();
    cancelSource2 = CancelToken.source();

    commit("SET_ITEMS");
    const params = {
      limit: options.itemsPerPage,
      offset: options.itemsPerPage * (options.page - 1),
      search: options.search,
      sortBy: options.sort_by,
      sortDirection: options.sort_direction,
      ...filterParams,
    };
    const request = { progress: false, cancelToken: cancelSource2.token };
    const res = await vm.$axios.$get(url, params, request);
    if (res?.succeeded) commit("SET_ITEMS", res?.data);
  },

  async INIT_STATUSES({ commit }) {
    const res = await vm.$axios.$get(`${url}/StatusLookUp`);
    if (res?.succeeded) commit("SET_STATUSES", res?.data);
  },

  async SET_TYPE(ctx, payload) {
    const guid = payload.guid;
    delete payload.guid;
    const res = await vm.$axios.$post(`${url}/${guid}/SetType`, payload);
    return res?.succeeded;
  },

  async PERMISSION_ORDER_API(ctx, payload) {
    const res = await vm.$axios.$post(`${url}Permission/CanUseOrder`, payload);
    return res?.succeeded;
  },
};

export const getters = {
  GET: (state) => state.itemsData.items,
  GET_COUNT: (state) => state.itemsData.total,
  GET_STATUSES: (state) => state.statuses,
};
