<script>
import { mapActions, mapGetters } from "vuex";
import { debounce } from "debounce";

import getProductTypes from "../../utils/product-types.js";

import TableSort from "@/components/table-sort.vue";
import ProductFilter from "@/components/modals/product-filter.vue";

export default {
  components: {
    TableSort,
    ProductFilter,
  },

  data: (vm) => ({
    options: {},
    page: 1,
    itemsPerPage: 50,

    isFilter: false,

    loaders: {},
    selected: {},

    filterParams: {},

    sortParams: {
      sort_by: '"CreatedOn"',
      sort_direction: "desc",
    },

    ln: "product",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    options: {
      handler() {
        this.getEntries();
      },
      deep: true,
    },
  },

  computed: {
    size: (vm) => vm.$store.getters.GET_SIZE,

    ...mapGetters("product", ["GET", "GET_COUNT"]),
    entries: (vm) => vm.GET,
    entriesCount: (vm) => vm.GET_COUNT,

    productTypes: (vm) => getProductTypes(vm),

    headers: (vm) => [
      {
        text: "#",
        value: "number",
        width: 40,
        align: "center",
      },
      {
        text: vm.tn("table.name"),
        value: "fullName",
        minWidth: "300px",
      },
      {
        text: vm.tn("table.search_variants"),
        value: "searchVariants",
        minWidth: "200px",
      },
      {
        text: vm.tn("table.type"),
        value: "type",
        minWidth: "200px",
        filter: (v) => vm.productTypes.find((x) => x.id == v),
      },
      {
        text: vm.tn("table.brand"),
        value: "brand",
        minWidth: "200px",
      },
      {
        text: vm.tn("table.manufacturer"),
        value: "manufacturer",
        minWidth: "200px",
      },
      {
        text: vm.tn("table.internationalNonproprietaryName"),
        value: "internationalNonproprietaryName",
        minWidth: "200px",
      },
      {
        text: vm.tn("table.isLinkedToPriceListLineItem"),
        value: "isLinkedToPriceListLineItem",
        minWidth: "200px",
      },

      ...vm.$baseTableColumns(vm),
    ],

    sortItems: (vm) =>
      vm.headers
        .filter((x) => x.value != "number" && x._sort != false)
        .map((x) => ({
          text: x.text,
          value: `"${vm.$upperCaseFirst(x.value)}"`,
        })),

    isLoading: (vm) => !!Object.keys(vm.loaders).length,
  },

  methods: {
    ...mapActions("product", ["GET_API"]),

    async getEntries() {
      let loaders = { ...this.loaders };
      const loadKey = this.$uuid();
      loaders[loadKey] = true;
      this.loaders = loaders;

      this.options.sort_by = this.sortParams.sort_by;
      this.options.sort_direction = this.sortParams.sort_direction;
      const filterParams = this.$filterParamsMap(this.filterParams);
      const options = { options: { ...this.options }, filterParams };
      await this.GET_API(options);

      loaders = { ...this.loaders };
      delete loaders[loadKey];
      this.loaders = loaders;
    },

    onSearch: debounce(function () {
      this.getEntries({ value: this.model });
    }, 200),

    onFilter(v) {
      this.filterParams = v;
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onSort() {
      this.page = 1;
      this.$nextTick(() => this.getEntries());
    },

    onClear() {
      this.options.search = null;
      this.$nextTick(() => this.getEntries());
    },
  },
};
</script>

<template>
  <div>
    <ProductFilter
      :value="filterParams"
      @input="onFilter"
      v-if="isFilter"
      @close="isFilter = false"
    />

    <div class="d-flex align-items-center pb-4">
      <h3>{{ tn("title") }}</h3>
      <v-spacer />

      <v-btn
        :title="tn('refresh')"
        @click="getEntries"
        :loading="isLoading"
        outlined
        color="primary"
        min-width="38"
        width="38"
      >
        <v-icon>mdi-sync</v-icon>
      </v-btn>

      <div class="px-2" />

      <table-sort
        :sortItems="sortItems"
        :disabled="isLoading"
        v-model="sortParams"
        @refresh="onSort"
      />

      <div class="px-2" />

      <v-badge
        dot
        overlap
        color="error"
        :value="$filterItemsCount(filterParams)"
      >
        <v-btn
          :title="tn('filter_btn')"
          @click="isFilter = true"
          outlined
          color="primary"
          min-width="38"
          width="38"
        >
          <v-icon>mdi-filter</v-icon>
        </v-btn>
      </v-badge>

      <div class="px-2" />

      <div>
        <v-text-field
          v-model="options.search"
          :label="tn('search')"
          class="w-250px"
          outlined
          dense
          hide-details
          @click:append="getEntries"
          append-icon="mdi-magnify"
          @click:clear="onClear"
          clearable
        />
      </div>
    </div>

    <v-data-table
      :height="size.height - 220"
      :headers="headers"
      :items="entries"
      :mobile-breakpoint="0"
      :loading="isLoading"
      fixed-header
      :options.sync="options"
      :server-items-length="entriesCount"
      :page.sync="page"
      :items-per-page.sync="itemsPerPage"
      :footer-props="{
        itemsPerPageOptions: [50, 100],
        showCurrentPage: true,
        showFirstLastPage: true,
      }"
      disable-sort
      dense
    >
      <template v-slot:body="props">
        <tbody>
          <tr
            class="cursor-pointer"
            :key="key"
            v-for="(item, key) in props.items"
            @click="selected = { ...item }"
            :class="selected.guid == item.guid && 'tr-active'"
          >
            <template v-for="head in props.headers">
              <td
                v-if="head.value == 'number'"
                :key="head.value"
                class="px-1 text-center"
              >
                <span
                  class="text-nowrap"
                  v-text="$sum((page - 1) * itemsPerPage + (key + 1))"
                />
              </td>

              <td v-else-if="head._filter" :key="head.value + ''">
                <div v-text="head._filter(item[head.value])" />
              </td>

              <td v-else :key="head.value + '1'">
                <div
                  :style="{ minWidth: head.minWidth }"
                  v-text="item[head.value]"
                />
              </td>
            </template>
          </tr>
        </tbody>
      </template>
    </v-data-table>
  </div>
</template>
