import Vue from "vue";
Vue.prototype.$serverUrl = process.env.VUE_APP_AXIOS_BASE_URL;
Vue.prototype.$version = process.env.VUE_APP_VERSION;

import "./plugins/axios";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";

import VueCookies from "vue-cookies";
Vue.use(VueCookies);

import "./plugins/resize-table.js";
import "./plugins/vue-inputmask.js";
import "./plugins/services.js";

import masks from "./utils/masks.js";
Vue.prototype.$masks = masks;

import "./utils/rules.js";
import "./utils/browser-store.js";
import "./utils/read-excel-data.js";

import "./assets/style.scss";

import "./filters";
import i18n from "./i18n";

Vue.config.productionTip = false;

import AutocompleteBox from "./components/fields/autocomplete-box.vue";
Vue.component("AutocompleteBox", AutocompleteBox);

import access from "./utils/access.js";
Vue.prototype.$access = access;

import baseTableColumns from "./utils/base-table-columns";
Vue.prototype.$baseTableColumns = baseTableColumns;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  i18n,

  beforeCreate() {
    const themeDark = localStorage.getItem("theme--dark");
    this.$vuetify.theme.dark = themeDark == "true";
  },
}).$mount("#app");
