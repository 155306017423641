<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    sortItems: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },

  data: (vm) => ({
    sortDirections: [
      { text: "Возрастанию", value: "asc" },
      { text: "Убыванию", value: "desc" },
    ],

    isMenu: false,
    path: vm.$route.path + "-table-sort",
  }),

  computed: {
    modelValue: {
      get: (vm) => vm.value,
      set(v) {
        this.$emit("input", v || {});
        if (v?.sort_direction && v?.sort_by) {
          this.$nextTick(() => {
            this.$browserStore.setSession(this.path, v);
            this.$emit("refresh");
            this.isMenu = false;
          });
        } else {
          this.$browserStore.delSession(this.path);
        }
      },
    },
  },

  methods: {
    onClear() {
      this.modelValue = {};
      this.$nextTick(() => {
        this.$emit("refresh");
        this.isMenu = false;
      });
    },

    onChange() {
      this.modelValue = { ...this.modelValue };
    },
  },
};
</script>

<template>
  <v-menu
    left
    bottom
    offset-y
    nudge-left="30"
    min-width="250"
    max-height="80vh"
    :close-on-content-click="false"
    v-model="isMenu"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-badge
        dot
        overlap
        color="error"
        :value="modelValue.sort_direction && modelValue.sort_by ? 1 : 0"
      >
        <v-btn
          :disabled="disabled"
          color="primary"
          min-width="38"
          width="38"
          outlined
          v-on="on"
          v-bind="attrs"
        >
          <v-icon> mdi-sort </v-icon>
        </v-btn>
      </v-badge>
    </template>

    <v-list dense class="bg-secondary">
      <div class="d-flex align-center px-3 pb-2">
        <small><b>Направление сортировки...</b></small>
        <v-spacer />
        <v-btn @click="onClear" icon>
          <v-icon> mdi-sort-variant-remove </v-icon>
        </v-btn>
      </div>
      <v-list-item-group
        @change="onChange"
        v-model="modelValue.sort_direction"
        color="primary"
      >
        <v-list-item
          v-for="item in sortDirections"
          :value="item.value"
          :key="item.value"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
      <div class="px-3 pb-2 pt-5">
        <small><b>Сортировать по...</b></small>
      </div>
      <v-list-item-group
        @change="onChange"
        v-model="modelValue.sort_by"
        color="primary"
      >
        <v-list-item
          v-for="item in sortItems"
          :value="item.value"
          :key="item.value"
        >
          <v-list-item-title>{{ item.text }}</v-list-item-title>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>
