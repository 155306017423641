<script>
export default {
  props: {
    value: { type: Array, default: () => [] },
    autofocus: { type: Boolean, default: () => false },
    required: { type: Boolean, default: () => false },
    label: String,
    items: Array,
  },

  data: () => ({
    select: [],
    isLoading: false,

    checkRequired: (e) => !!e?.length || "",
  }),

  watch: {
    value(v) {
      this.select = this.$array(v);
    },

    select(v) {
      this.$emit("input", v);
    },
  },

  created() {
    if (this.value) {
      const values = this.$array(this.value);
      this.select = values;
    }
  },

  methods: {
    onSelectAll() {
      this.select = this.$array(this.items);
    },
  },
};
</script>

<template>
  <v-autocomplete
    :items="items"
    :loading="isLoading"
    item-text="name"
    item-value="guid"
    outlined
    v-model="select"
    multiple
    disable-lookup
    clearable
    return-object
    :autofocus="autofocus"
    hide-details
    :label="label"
    chips
    deletable-chips
    small-chips
    hide-selected
    dense
    :required="required"
    :rules="required ? [checkRequired] : []"
    append-icon="mdi-check-all"
    @click:append="onSelectAll"
  />
</template>
