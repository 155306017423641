<script>
import { mapActions } from "vuex";

import ModalsTemplate from "../../modals-template.vue";

export default {
  components: { ModalsTemplate },

  props: {
    editData: {
      type: Object,
    },
  },

  data: (vm) => ({
    dialog: true,
    isLoading: false,
    isInit: false,

    formData: {
      orderNumber: null,
      nameRu: null,
      nameUz: null,
      nameUzLat: null,
      count: null,
      icon: null,
      color: null,
      url: null,
    },

    ln: "home_page_tile.add",
    tn: (n) => vm.$t(vm.ln + "." + n),
  }),

  watch: {
    dialog(v) {
      if (!v) setTimeout(() => this.$emit("close"), 100);
    },
  },

  created() {
    this.init();
  },

  methods: {
    ...mapActions("home-page-tile", ["ADD_API", "GET_BY_GUID_API"]),

    async init() {
      if (this.editData?.guid) {
        this.$store.dispatch("SET_LOADING", true);
        const data = await this.GET_BY_GUID_API(this.editData);
        if (data) this.formData = data;
        this.$store.dispatch("SET_LOADING", false);
      }

      this.isInit = true;
    },

    async onSave() {
      if (this.$refs.form.validate()) {
        this.isLoading = true;
        const formData = { ...this.formData };
        formData.color = formData.color?.hex || formData.color;
        const data = await this.ADD_API(formData);
        this.isLoading = false;
        if (data) {
          this.$emit("saved", data);
          this.dialog = false;
        }
      } else {
        this.$store.dispatch("SET_SNACKBAR", {
          text: this.$t("fill_required"),
          color: "info",
        });
      }
    },

    onOpenIcons() {
      window.open(
        "https://pictogrammers.github.io/@mdi/font/6.6.96/",
        "_blank"
      );
    },
  },
};
</script>

<template>
  <modals-template
    v-if="isInit"
    :width="450"
    :title="tn('title')"
    v-model="dialog"
  >
    <v-form
      :disabled="isLoading"
      ref="form"
      lazy-validation
      onSubmit="return false;"
    >
      <v-text-field
        autofocus
        dense
        :label="tn('form.order_number')"
        outlined
        v-model="formData.orderNumber"
        required
        :rules="[$rules.required]"
        @keyup.enter="onSave"
        v-inputmask="$masks.number"
        hide-details
      />

      <div class="pb-4" />
      <v-text-field
        :label="tn('form.name_ru')"
        outlined
        v-model="formData.nameRu"
        required
        :rules="[$rules.required]"
        dense
        hide-details
      />

      <div class="pb-4" />
      <v-text-field
        :label="tn('form.name_uz')"
        outlined
        v-model="formData.nameUz"
        required
        :rules="[$rules.required]"
        dense
        hide-details
      />

      <div class="pb-4" />
      <v-text-field
        :label="tn('form.name_uz_lat')"
        outlined
        v-model="formData.nameUzLat"
        required
        :rules="[$rules.required]"
        dense
        hide-details
      />

      <div class="pb-4" />
      <v-text-field
        dense
        :label="tn('form.count')"
        outlined
        v-model="formData.count"
        required
        :rules="[$rules.required]"
        @keyup.enter="onSave"
        v-inputmask="$masks.number"
        hide-details
      />

      <div class="pb-4" />
      <v-text-field
        dense
        :label="tn('form.icon')"
        outlined
        v-model="formData.icon"
        required
        :rules="[$rules.required]"
        @keyup.enter="onSave"
        hide-details
        :prepend-inner-icon="formData.icon"
        append-icon="mdi-open-in-new"
        @click:append="onOpenIcons"
      />

      <div class="pb-4" />
      <v-text-field
        autofocus
        dense
        :label="tn('form.url')"
        outlined
        v-model="formData.url"
        @keyup.enter="onSave"
        maxlength="200"
        hide-details
        clearable
      />

      <div class="pb-4" />
      <v-color-picker
        v-model="formData.color"
        width="400"
        mode="hexa"
        swatches-max-height="100"
        hide-mode-switch
      />

      <v-btn
        height="45"
        :loading="isLoading"
        color="primary"
        @click="onSave"
        block
        elevation="0"
        class="mt-5 mb-1"
      >
        {{ tn("save_btn") }}
      </v-btn>
    </v-form>
  </modals-template>
</template>
